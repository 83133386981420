* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
body { font-family: 'Open Sans', sans-serif !important;  margin: 0; }

.topnav { background-color: #eee; height: 28px; }
.midnav { background-color: #fff; float: left; width: 100%; padding-right: 20px; padding-left: 20px; }

ul { margin: 0; }
ol, p, ul { margin-top: 0; }
a, fieldset[disabled] .w-input, fieldset[disabled] .w-select { color: #fff; }

.legal-template a {
    color: black;
    text-decoration:underline;
}
iframe {
    position: relative !important;
}

.full-width { width: 100%; }

.w-container { max-width: 1170px; padding: 0 20px; margin-left: auto; margin-right: auto; } /* Needs to be deleted */

.fa-search { pointer-events: none; color: #fff; font-size: 1.5em; }
#pfQoSearchBtn .fa-search { pointer-events: none; padding: 0px 0px 0px 0px; top: -3px; position: relative; float: right; z-index: 1000; right: 8px; color: #fff; font-size: 1.5em; }
#pfQoSearchBtn.pf-btn.pf-btn-colour-one:hover { background-color: #f7126d !important; color: #f7126d !important; text-decoration: none !important; border-color: #f7126d !important; }
.topnav .nav-link.mid { padding: 5px 10px 2px 10px; }
.w-nav, .w-nav-brand, .w-nav-link, .w-nav-menu { position: relative; }

.header { position: fixed; left: 0; display: block; top: 0; right: 0; z-index: 1000; box-shadow: 3px 3px 15px 0 rgba(0,0,0,.2); height: auto; }
.header + .section { padding-top: 180px !important; }
.ui-menu { z-index: 1000; } /* Sorts the layering of the search dropdown */
.the-website.pf-promotion-strip-enabled .ecs-main-container, .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp { padding-top: 180px;}/*Increase padding when promotional strip it is enable*/
/* Header shrink properties - start */
.brand, .topnav, .nav-link, .topnav .nav-link.mid, .searchform-wrapper, .nav-link.mid, .nav-link.nav-dropdown.w--open, .mega-list.w--open, .midbav, .menu-button, .w-icon-nav-menu, .w-nav-button { transition: all 0.4s ease-in-out !important; -webkit-transition: all 0.4s ease-in-out !important; -moz-transition: all 0.4s ease-in-out !important; }
.webui-popover {
    z-index: 900 !important;
}
/* New Style updates added by Alex - start */
.mobile-search-icon { display: none; }
#search-form { display: block; }
.svg-inline--fa {
	display: inline-block;
	font-size: inherit;
	height: auto;
	overflow: visible;
	vertical-align: -0.18em;
}

.ui-widget-content { border: 1px solid #ddd; background: #fff; color: #666; padding: 2px; }
.ui-state-focus, .ui-state-hover, .ui-widget-content .ui-state-focus, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-focus, .ui-widget-header .ui-state-hover, .ui-widget-content .ui-menu-item.ui-state-focus { border: 0px solid #ccc; color: #c06; font-weight: normal; }
#ui-id-1 { display: block; }
body .ui-autocomplete { z-index:9999; color:#666; cursor:pointer; }
.fa-search { pointer-events: none; padding: 0px 0px 0px 0px; top: -31px; position: relative; float: right; z-index: 1000; right: 12px; color: #fff; font-size: 1.5em; }
.link-search-div .fa-search,.search-section .fa-search {color:#f7126d; top:0; right:2px; float:none; }
.search-section .search-close { color:#f7126d}
.create-wishlist { margin-bottom: 10px !important; }
input[type=text].pf-create-wish-list, input[type=text].pf-edit-wishlist { font-size: 1em !important; padding: 0px 5px 0px 5px; margin-top: 10px; margin-bottom: 10px; }
.ecom-filter-group { position: relative; float: left; width: 100%; }
/*#collapseWishlist { position: relative; float: left; width: 10%; text-align: right; }*/
.pf-all-wishlists .dropdown-toggle.pf-filter-item { background-color: #f1f1f1; }
.wishListName { color: #666; }
.pf-all-wishlists .dropdown-toggle.pf-filter-item { color: #f7126d; }
.edit-wishlist-name-btn { float: right; margin-right: 20px; }
/*#WishlistDropdown::before { content: "Open wishlist"; }*/

.wishlist-main-buttons .create-wish-List-btn {
    margin-bottom: 5px;
}

#wishlistCheckboxName span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    text-align: left;
}

.post-edit-wishlist-btn, .cancel-edit-wishlist-name-btn {
    padding-top: 8px;
}

.pf-wishlist-edit-name:not(.hidden) + #WishlistDropdown {
    padding-top: 17px;
}


.pf-all-wishlists .filter-dropdown { background-color: #fff; }
.pf-all-wishlists .pf-filter-item input { top: 0px; }
.post-edit-wishlist-btn, .cancel-edit-wishlist-name-btn { padding: 0px 5px 5px 5px; margin-top: 10px; position: relative; float: left; }
.cancel-edit-wishlist-name-btn { padding: 0px 15px 5px 5px; }
.pf-filter-dropdown-icon .fa-caret-down { font-size: 20px; }

.spec-table table tbody > tr > td { padding: 3px 5px 3px 5px !important; }
.spec-table tbody td:nth-child(2n+1) { width: 25%; }
.stock-count { margin-right: 4px; }
.variantAddToBasketWrapper { display: table; }
.ecs-row-container.cart-buttons-popup { padding-left: 0px; padding-right: 0px; }
/*#checkout-cart td:nth-of-type(7), #checkout-cart td:nth-of-type(8), #checkout-cart td:nth-of-type(9) { display: none; }*/
.pf-button:hover { background-color: #fff; color: #f7126d; text-decoration: none; border-color: #fff !important; }
.pf-button a:hover { background-color: #fff; color: #f7126d; text-decoration: none; border-color: #fff !important; }
.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover { border-color: transparent; background-color: #fff; color: #e5005b; text-decoration: none; text-shadow: none; }
.pf-button a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover { text-shadow: none; background-color: #fff; color: #e5005b; border: 1px solid #fff; }
.pf-button a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px 26px;
	-webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
	transition: color .5s ease,background-color .5s ease,border .6s ease;
	font-family: Avenir;
	font-size: 12px;
	line-height: 21px;
	box-shadow: 2px 2px 10px 0 
	rgba(0,0,0,.2);
	color:
	#fff;
	text-transform: uppercase;
}
.login-top { margin-top: 1%; }
.login-buttons { position: relative; float: left; width: 100%; }
.account-login { border-right: 1px solid #ccc; padding-right: 5%; margin-top: 1%; }
.ecs-row.pf-temp-access-wrapper {
    display: flex;
    flex-direction: column;
}

.ecs-row.pf-temp-access-wrapper .ecs-column-2 {
    align-self: center;
}
.pf-btn.pf-btn-grey { border: 1px solid #ccc !important; color: #000 !important; background: #ccc !important; cursor: pointer !important; }
.pf-btn.pf-btn-grey:hover { background-color: #fff !important; color: #f7126d !important; text-decoration: none !important; border-color: #f7126d !important; cursor: pointer !important; }
#pf-product-list-page .ecs-row.product-category .ecs-column-2 { margin-right: 2%; }
#pf-product-list-page .ecs-row.product-category .ecs-column-2:last-child { width: 50%; margin-right: 0%; }

/*.cart-total-qty { position: absolute; top: 3px; left: -29px; color: #fff; }*/
/*.fa-shopping-cart, .fa-user { font-size: 2.3em; }
.fa-user { margin-right: 10px; }*/
.mobile-search-icon { display: none; }

#pfQoSearchResults td, #pfQoSearchResults th { padding: 5px 5px 5px 10px !important; }
#pfQoSearchResults { margin-bottom: 10px; }
.pf-wishListHeader { position: relative; float: left; font-weight: bold; padding: 5px; }
.pf-add-cart-row .pf-qty-wrapper .pf-text-center { text-align: right; }
.pf-promotion-strip-enabled .ecs-main-container-nbp { padding-top: 180px;} /* Adjust padding when promotional strip is enable*/
#billing-manual-wrapper .note-text { text-align: center; }
.ecs-radio label { display: block; margin-bottom: 5px; float: left; width: 100%; margin-left: 18px; }
.ecs-radio label input[type='radio'] { margin-left:-18px; }
.pf-qty-wrapper input { border-radius: 0; }

.recently-viewed-section { padding-bottom: 10px; }
.recently-viewed-container { padding: 15px 20px 10px 20px; }
.recently-viewed-section .testimonial-header, .ecs-tab-wrapper .testimonial-header { font-size: 20px; font-weight: bold; text-align: center; margin-bottom: 10px; }
.pf-recent-related-product-item .w-inline-block { width: 80%; background-color: #fff; padding: 8px; border: solid 1px #ccc; }
.pf-recent-related-product-item.horizontal-scroll-item { flex: 0 auto; width: 20%; }

.pf-recent-related-product-item .w-inline-block .product-code { color: #999; font-size: 12px; line-height: 20px; text-transform: uppercase; }
.pf-recent-related-product-item .w-inline-block .product-mini-descriptions { font-size: 18px; line-height: 26px; color: #666; text-decoration: none; }
.pf-recent-related-product-item .w-inline-block .recently-viewed-price { font-size: 14px; line-height: 26px; color: #666; text-decoration: none; }


.recently-viewed-section .horizontal-scroll-box, .recently-viewed-container .horizontal-scroll-box { padding-left: 30px; padding-right: 30px; }
.recently-viewed-section .horizontal-scroll-box .w-icon-slider-left, .recently-viewed-container .horizontal-scroll-box .w-icon-slider-left { top: 38% !important; color:  #bbb; /*border: solid 2px;*/ border-radius: 40px; padding: 5px 13px 5px 9px; }
.recently-viewed-section .horizontal-scroll-box .w-icon-slider-right, .recently-viewed-container .horizontal-scroll-box .w-icon-slider-right { top: 38% !important; color:  #bbb; /*border: solid 2px;*/ border-radius: 40px; padding: 5px 9px 5px 13px; }

.recently-viewed-section .horizontal-scroll-box .w-icon-slider-left:hover, .recently-viewed-container .horizontal-scroll-box .w-icon-slider-left:hover { color:  #999; }
.recently-viewed-section .horizontal-scroll-box .w-icon-slider-right:hover, .recently-viewed-container .horizontal-scroll-box .w-icon-slider-right:hover { color:  #999; }

.recently-viewed-section .svg-inline--fa.fa-w-10, .recently-viewed-container .svg-inline--fa.fa-w-10 { width: .5em; }
.recently-viewed-section .svg-inline--fa, .recently-viewed-container .svg-inline--fa { display: inline-block; font-size: inherit; height: auto; overflow: visible; vertical-align: -0.15em; }

.scroll-arrow-left, .scroll-arrow-right { position: relative; width: 80px; /*top: 0; right: 0; bottom: 0; left: 0;*/ height: auto; cursor: pointer; overflow: hidden; margin-top: 0; font-size: 40px; float: left; }
.flex-center { display: flex; justify-content: center; }

.recently-viewed-section .scroll-arrow-left, .recently-viewed-section .scroll-arrow-right, .recently-viewed-container .scroll-arrow-left, .recently-viewed-container .scroll-arrow-right  { position: relative; width: 50px; top: 0; right: 0; bottom: 0; left: 0; height: auto; cursor: pointer; overflow: hidden; margin-top: 0; font-size: 40px; float: left; }
.pf-related-recent-tab-content .pf-recent-related-product-item { background-color: rgba(255,255,255,0.00) !important; }


.full-width { width: 100vw !important; position: relative; left: 50%; right: 50%; margin-left: -50vw; margin-right: -50vw; }
.recently-viewed-section.full-width { padding-top: 10px; background-color: #f6f6f6 ; border-top: solid 1px #ddd; }
.pf-related-recent-tab-content .ecs-tab-wrapper { max-width: 1200px; margin: 0 auto; }
.pf-related-recent-tab-content .ecs-tab-wrapper { max-width: 1200px; margin: 0 auto; }
.recently-viewed-container.full-width { background-color: #eee; border-top: solid 1px #ccc; }

/*#pf-related-recent-products-wrapper { border-top: solid 1px #dedede; }*/
#pf-product-details-page.ecs-main-container { padding-bottom: 0px !important; }
#pf-product-details-page.pf-page-wrapper { margin-bottom: 0px !important; }


/* new style for recommended and related product slider */
.slick-slider div { transition: none; }
.pf-related-product-zone .ecs-row{
    display:block;
}
.pf-recent-related-header{
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 0;
    font-weight: 400;
    text-align:center;
}
.pf-related-product-zone{
    min-height:350px;
    clear:both;
}
.pf-slide-item{ 
    margin-left: 20px;
    margin-right: 20px;
    background-color: #fff;
    text-align: center;
    padding: 5px 5px 20px 5px;
    border: 1px solid #eee;
    transition: color .5s ease,opacity .5s ease,transform .6s ease,box-shadow .9s ease,-webkit-transform .6s ease;
}
.pf-slide-item:hover {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.25);
}
.pf-slide-item .pf-product-name{
    font-size: 16px;
    line-height: 20px;
}
.pf-slide-item .pf-product-price{
    font-size:14px;
    margin-top:5px;
}
.pf-slide-item img{
    margin-bottom:10px;
}
.pf-related-list-wrapper, .pf-recent-list-wrapper{
    margin-left:50px;
    margin-right:50px;
    margin-top:20px;
    margin-bottom:20px;
    position:relative;
}
.pf-related-viewed-section{
    background-color: #f6f6f6;
    border-top: solid 1px #ddd;
    padding-top: 20px;
    padding-bottom:30px;
}
.pf-recent-viewed-section{
    background-color: #eee;
    border-top: solid 1px #ddd;
    padding-top: 20px;
    padding-bottom:30px;
}
.pf-slider-button{
    position: absolute;
    top: 38%;
    font-size: 41px;
    color: #bbb;
    cursor:pointer;
    opacity:0.7;
}
.pf-slider-button.pf-slider-button-prev {
    left: -40px;
}
.pf-slider-button.pf-slider-button-prev:hover, .pf-slider-button.pf-slider-button-next:hover{
    opacity:1;
}
.pf-slider-button.pf-slider-button-next {
    right: -40px;
}

.slick-initialized .slick-track {
    display: flex;
}
.slick-initialized .slick-track .slick-slide {
    height: auto;    
}

.lobibox-notify-msg h1 { font-size: 25px !important; line-height: 25px !important; }
.lobibox-notify .lobibox-notify-body { margin-left: 100px !important; }
.lobibox-notify.lobibox-notify-success, .lobibox-notify.lobibox-notify-warning { border-color: #ccc !important; background-color: #fff !important; color: #666 !important; }
.lobibox-notify.lobibox-notify-success:hover { background-color: #fff !important; border-color: #ccc !important; }
.lobibox-notify .lobibox-close { right: 8px !important; top: 8px !important; border: solid 2px !important; line-height: 14px !important; }
.lobibox-close { color: #666 !important; }
.lobibox-notify .lobibox-close:hover { background-color: rgba(0,0,0,.5); font-weight: 700; color: #fff !important; border: solid 2px #666 !important; }
.lobibox-notify-title { padding-top: 10px !important; color: #29B87E !important; }

.details.pf-order-details table tbody > tr > td { padding: 0px 0px 5px 0px !important; }
.nav-link-dropdown:first-of-type .dropdown-list.w--open { right: auto; left: 15px; padding-left: 10px; padding-right: 10px; }
.nav-link-dropdown:last-of-type .dropdown-list.w--open { left: auto; right: 30px; padding-left: 10px; padding-right: 10px; }
.pf-modal-wishlist.remodal { padding: 0px 35px 35px 35px; }
.pf-modal-wishlist h3 { padding-bottom: 10px; }
.wishlist-main-buttons { width: 100%; text-align: center; padding-top: 10px; padding-bottom: 0px; }
.pf-modal-wishlist-image img { border: solid 1px #ccc; padding: 5px; }

.pf-order-details, .pf-return-details { background-color: #fff !important; padding: 10px; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; }
.pf-order-details table thead, .pf-return-details table thead { background-color: #f1f1f1; }
.pf-order-return-table th { text-align: center; }
.pf-order-return-table td { padding: 5px 0px 5px 0px!important; }
.pf-order-return-qty { width: 20%; min-width: 40px; }
.pf-return-details-notes { width: 100%; }
.returns-text-container { border-bottom: solid 1px #ccc; }

.pf-favs-table tr:nth-child(even) { border-bottom: solid 1px #ccc; }

.wishlist-main-buttons .pf-btn.pf-btn-secondary, .wishlist-main-buttons .add-to-favorites-btn { background: #fff; border: 1px solid #f7126d; color: #f7126d; min-width: 170px !important; }
.wishlist-main-buttons .pf-btn.pf-btn-secondary:hover { background-color: #f7126d; border-color:#f7126d; color: #fff; text-decoration: none; min-width: 170px !important; }

.fav-item-img-mobile { display: none; }
.ecs-column-2.footer-links { width: auto; max-width: 47.25%; padding-right: 10%; }
#favs-wrapper .pull-right { text-align: center; }
#favs-wrapper .pull-right .pf-add-all-wishlists-to-cart-btn { margin-top: 15px; }
#bag-opener { overflow: hidden; }

.ecs-row-full-width.blog-list .ecs-column-2 { width: 23.125%; }
.ecs-row-full-width.blog-list .ecs-column-2, .ecs-row-full-width.blog-list .ecs-column-2:nth-child(2) { margin-right: 2.5% !important; }
.ecs-row-full-width.blog-list .ecs-column-2:last-of-type { margin-right: 0% !important; }
.ecs-row-full-width.blog-list .ecs-column-2.blogPostStackIpad h1 { display: none; }

.footer-links p { margin-bottom: 0; }
/* New Style updates added by Alex - end */

/* Top cart holder properties - start */
#top-cart-holder { position: relative; float: left; width: 100%; }
.errormessage-block { position: relative; float: left; width: 100%; text-align: center; font-size: 20px; margin-top: 10px; margin-bottom: 0px; }
.pf-checkout-cart-table td { border: 0px solid #ccc; }
td.cart-description { text-align: center; }
.pf-btn { border-radius: 0px; }
/* Top cart holder properties - end */

.brand { position: absolute; left: 50%; display: inline-block; padding-top: 15px; padding-bottom: 10px; -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0); }

.input.inline { min-height: 40px; margin-bottom: 0; }
.input { border-color: #f7126d; border: 1px solid #ddd; }

.w-input, .w-select { display: block; width: 100%; height: 38px; padding: 0px 12px; margin-bottom: 10px; font-size: 14px; line-height: 1.428571429; color: #333; vertical-align: middle; background-color: #fff; }

.searchform-wrapper { display: inline-block; width: 100%; float: left; max-width: 280px; margin: 20px auto 0; }
.searchform { position: relative; }
#prod-search-btn { color: #fff; background-color: #f7126d; padding: 10px; }
.w-button { display: inline-block; color: #fff; border: 0; line-height: inherit; cursor: pointer; border-radius: 0; }
ul.mbss-textArea-btns { position: relative !important; top: 40px !important; z-index: 99999 !important; float: left !important; }

/* Bottom cta properties */
.home-element-wrapper { display: inline-block; width: 33.33%; margin-bottom: 60px; padding-right: 10px; padding-left: 10px; float: left; font-size: 17px; font-weight: 300; }
.home-element-wrapper-4 {
    display: inline-block;
    width: 25%;
    margin-bottom: 60px;
    padding-right: 10px;
    padding-left: 10px;
    float: left;
    font-size: 17px;
    font-weight: 300;
}
.title-wrapper { position: relative; text-align: center; z-index: 10; font-size: 19px; line-height: 27px; margin-bottom: 20px; margin-top: 10px; float: left; }
.title-wrapper-2 { position: relative; line-height: 27px; }
.title-wrapper-2.bottom { color: #fff; text-align: center; }

.img-bg-section, .bg-section { position: relative; float: left; width: 100%; padding-top: 80px; padding-bottom: 80px; }
.bg-section { padding-top: 0px; padding-bottom: 0px; }
.brandsection { padding: 60px 20px; background-position: 0 0,50% 50%; background-size: auto,cover; color: #fff; text-align: center; }

.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
	-webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
	transition: color .5s ease,background-color .5s ease,border .6s ease;
	border: 1px solid #f7126d;
	box-shadow: 2px 2px 10px 0 rgba(0,0,0,.2);
	color: #fff;
	text-shadow: 1px 1px 6px rgba(0,0,0,.9);
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px 26px 10px 26px;
	font-size: 15px;
	line-height: 21px;
	background-color: transparent;
	text-transform: uppercase;
}

.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover, .nav, .pf-page-wrapper .pf-tab-header { color: #fff; background-color: #f7126d; }

.w-row { position: relative; float: left; width: 100%; }
/* End */

.bag-opener.nav-link.mid.w-nav-link { float: right; }
.nav { background-color: #aeabad; }
.nav-link {
	color: #fff;
	text-transform: uppercase;
	padding: 22px 20px;
	-webkit-transition: color .5s ease,background-color .5s ease;
	transition: color .5s ease,background-color .5s ease;
/*	font-family: 'Open Sans',sans-serif;*/
	font-size: 13px;
	letter-spacing: .5px;
}

.w-nav-link { display: inline-block; text-align: left; margin-left: auto; margin-right: auto; }

.section { position: relative; float: left; width: 100%; padding-top: 20px; padding-bottom: 20px; background-color: #fff; }
.section .top-categories { padding-top: 0px !important; }

.title-wrapper { text-align: center; }
.title-wrapper h2 { padding-top: 30px; }
.text-align-center { text-align: center !important; }
.section.title-wrapper h2 { padding-top: 20px; line-height: 40px; margin-bottom: 30px; }

.blogpost-wrapper { position: relative; overflow: hidden; display: block; margin-right: auto; margin-bottom: 20px; margin-left: auto; clear: both; text-align: center; }

.blogpost-caption h1, .blogpost-caption h2, .blogpost-caption h3, .blogpost-caption h4 { margin-top: 20px; margin-bottom: 5px; font-weight: normal; }
.blog-img-large { min-height: 695px !important; display: block !important; padding-bottom: 0; }
.blogDescription { padding: 10px 0px 10px 0px; width: 100%; }
.blog-img-large._1 { background-position: 50% 50%; background-size: cover; }

.button {
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px 26px 13px 26px;
	-webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
	transition: color .5s ease,background-color .5s ease,border .6s ease;
	font-family: 'Open Sans',sans-serif;
	font-size: 15px;
	line-height: 21px;
	text-shadow: none;
	border: 1px solid #fff; color: #fff; background: #f7126d;
}
.button p { font-weight:normal; }
.button:hover { background-color: #fff; color: #f7126d; text-decoration: none; border-color: #f7126d; }

.button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px 26px;
	font-size: 12px;
	line-height: 21px;
	background-color: transparent;
	text-transform: uppercase;
	border: 1px solid #f7126d;
	color: #f7126d;
	-webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
	transition: color .5s ease,background-color .5s ease,border .6s ease;
}

.button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover { color: #fff; background-color: #f7126d; border-color: #f7126d; }

/* Homepage slider properties */
.slider { top: 0px; position: relative; height: 70vh; background-color: transparent; max-height: 700px; min-height: 400px; }
.w-slider-mask { position: relative; height: 100%; display: block; z-index: 1; left: 0; right: 0; overflow: hidden; white-space: nowrap; }
.w-slide { position: relative; height: 100%; display: inline-block; vertical-align: top; width: 100%; white-space: normal; text-align: left; }
.banner-text-large { margin-bottom: 12px; font-size: 40px; line-height: 48px; text-transform: none; }

.slider-container {
	z-index: 10;
	padding-top: 30px;
	padding-bottom: 40px;
	background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,.5),rgba(0,0,0,.5));
	background-image: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.5));
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
	text-align: center;
	text-shadow: 1px 1px 6px rgba(0,0,0,.5);
}

.slider-container, .testimomonial-bg { position: absolute; left: 0; right: 0; bottom: 0; }

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
	display: inline-block;
	margin-top: 20px;
	margin-right: 4px;
	margin-left: 4px;
	padding: 12px 27px 15px 27px;
	border: 1px solid #fff;
	background-color: #e5005b;
	-webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
	transition: color .5s ease,background-color .5s ease,border .5s ease;
	color: #fff;
	font-size: 17px;
	line-height: 21px;
	font-weight: 400;
	text-shadow: none;
}

.w-icon-slider-left { margin: 0; position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%); left: 5px; }
.w-icon-slider-right { margin: 0; position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%); right: 5px; }
.horizontal-scroll-box .w-icon-slider-left { padding-left: 0%; }
.horizontal-scroll-box  .w-icon-slider-right { padding-left: 70%; }
.w-slider-arrow-left, .w-slider-arrow-right { position: absolute; width: 45px; top: 0; bottom: 0; right: 0; cursor: pointer; color: #fff; font-size: 40px; }
.w-slider-arrow-left { z-index: 3; right: auto; }
.w-slider-arrow-right { z-index: 4; left: auto; }

.w-slider-nav { position: absolute; z-index: 2; top: auto; right: 0; bottom: 0; left: 0; margin: auto; padding-top: 10px; height: 40px; text-align: center; }
.w-slider-nav.w-round > div { border-radius: 100%; }
.w-slider-dot { position: relative; display: inline-block; width: 1em; height: 1em; background-color: rgba(255,255,255,.4); cursor: pointer !important; margin: 0 3px .5em; transition: background-color .1s,color .1s; }
.w-slider-dot.w-active { background-color: #fff; }

/* Homepage category boxes */
.flex-parent { text-align: center; display: block; height: auto; margin-right: auto; margin-left: auto; }

.text-wrapper { color: #fff; text-align: center; }
.title-wrapper { text-align: center; width: 100%; }
.title-wrapper.bottom { color: #fff !important; }

.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) { background-color: #e5005b; text-shadow: none; border: 1px solid #fff; }
.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover { text-shadow: none; background-color: #fff; color: #e5005b; border: 1px solid #fff; }

.flex-child.extra-small, .flex-child.small { width: 32.25%; }
.flex-child { position: relative; display: inline-block; width: 65.3%; margin-right: 0.3%; margin-bottom: 0.75%; margin-left: 0.3%; float: none; -webkit-transition: opacity .5s ease-in-out; transition: opacity .5s ease-in-out; }
.flex-parent h3 { margin-bottom: 30px; font-weight: 400; }

.center-flex {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0px 5px 1px;
	background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,.5),rgba(0,0,0,.5));
	background-image: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.5));
	color: #fff;
	font-size: 19px;
	line-height: 27px;
	text-shadow: 1px 1px 4px rgba(0,0,0,.3);
}

.center-flex h2 { line-height: 83px; }

.img-overlay2 { display: block; width: 100%; border: 1px solid #eee; background-position: 50% 50%; background-size: cover; background-repeat: no-repeat; }
.img-overlay { display: block; width: 100%; height: 600px; border: 1px solid #eee; background-position: 0 0; background-size: 125px; margin-right: auto; margin-left: auto; overflow: hidden; }

.img-overlay2 { height: auto; }
.image-height-320 { height: 320px;}
.gridcat {
    background-position: 50% 50%;
    background-size: cover,125px;
    background-repeat: no-repeat,repeat;
}

/* homepage collection images */
.pf-image-collection a:not(.mbss-saveTxtBtn, .mbss-cancelTxtBtn) {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* CMS */
body { color: #333; font-size: 15px; line-height: 21px; font-weight: 400; text-align: left }

h1 { font-size: 40px; line-height: 48px; font-weight: 400; }
h2 { margin-top: 10px; margin-bottom: 10px; font-size: 38px; line-height: 46px; font-weight: 400; letter-spacing: 1px; text-transform: none }
h3 { font-size: 27px; line-height: 34px; letter-spacing: 0; margin-top: 20px; margin-bottom: 10px; }
.flex-parent h3 { margin-bottom: 30px; font-weight: 400; }

.mbss-content h3 { margin-bottom: 30px; font-weight: 400; }
.h3-text-no-padding .mbss-content h3 { margin-bottom: 0px; font-weight: 400; }
.mid-footer .mbss-content h3 { margin-bottom: 10px; font-weight: 400; }

h4, h5, h6 { font-weight: 700; margin-bottom: 10px }
h4 { margin-top: 20px; font-size: 19px; line-height: 24px }
h5 { font-size: 14px; line-height: 20px }
h6 { font-size: 12px; line-height: 18px }
p { font-weight: 300 }

h4 { text-transform: uppercase }
ul { margin-top: 0 }
label { font-weight: 400 }

a { -webkit-transition: color .5s ease; transition: color .5s ease; text-decoration: none; }
a:hover { color: #666; text-decoration: none; }

.nav, .nav-link.nav-dropdown.w--open, .nav-link:hover { color: #fff; }
ul { margin-bottom: 10px; padding-left: 20px; }
.nav { 	border-top: 1px none hsla(0, 0%, 7%, .08); /* Remove ? */ position: relative; float: left; width: 100%; top: 0px; }
.nav.top { background-color: #eee; text-align: center }
.nav-link { display: inline-block; padding: 11px 20px 10px 20px; -webkit-transition: color .5s ease, background-color .5s ease; transition: color .5s ease, background-color .5s ease; font-size: 13px; letter-spacing: .5px; line-height: 15px; }

.nav-link:hover { text-decoration: none; }
.nav-link.w--current { margin-top: 0; margin-bottom: 0; color: #fff; }
.nav-link.nav-dropdown { z-index: 10; padding-right: 20px; padding-top: 1px; line-height: 21px; }
.nav-link.nav-dropdown.w--open { color: #fff; line-height: 21px; }

/* Mega menu - start */
.w-col-3 { width: 25% !important; }

.mega-list.w--open { /*left: -362px; right: auto;*/ width: 940px; margin-top: 0px; padding: 0px 10px 20px 10px; background-color: #fff; border: 1px solid #f7126d; }

.megaheading, .nav-container .heading { 
    border-color: #f7126d; 
    color: #f7126d !important;
    text-transform:uppercase;
}
.ecs-nav-group { padding-bottom: 10px; }
#sidenav .ecs-nav-group .heading { font-size: 12px !important; }
#sidenav .ecs-nav-group .dropdownlink { font-size: 12px !important; }

a.dropdownlink:hover { color: #e5005b; text-decoration: none; }
.megaheading {
	margin-top: 10px;
	-webkit-transition: color .5s ease,background-color .5s ease;
	transition: color .5s ease,background-color .5s ease;
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
}

.w-dropdown-link {
	padding: 10px 20px;
	display: block;
	vertical-align: top;
	text-decoration: none;
	white-space: nowrap;
	position: relative;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
}

.megalink:hover { color: #e5005b; text-decoration: none; }
.dropdown-icon { position: absolute; top: 0; bottom: 0; right: 0; margin-top: 1px; width: 1em; }
/* Mega menu - end */

.nav-link.top { padding: 9px 10px; color: hsla(0, 0%, 40%, .6); font-size: 12px; letter-spacing: .3px; text-transform: none }
.banner-text-large, .button, .new, .slider-button, .tag.w--current { letter-spacing: 1px }
.nav-link.top:hover { color: #666 }
.nav-link.top.w--current { background-color: transparent; color: #666 }
.nav-link.top.pull-right { display: inline-block }
.nav-link.top.pull-right.show-mobile { display: none }
.nav-menu, .nav-menu.top { display: block; text-align: center }
.nav-link._2 { width: 50px; padding-right: 0; padding-left: 0; text-align: center }
.nav-link.mid { border-color: #f7126d; color: #f7126d; text-transform: none; padding: 32px 0px 20px 10px; float: right; }
.nav-link.mid:hover { color: #666 }
.nav-link.mid.w--current { background-color: transparent; color: #666; }
.nav-menu { float: none; background-color: #aeabad; }
.nav-menu.top { width: 100%; background-color: transparent; position: relative; float: left; }
.section.products, .section.social, .section.tint.hide { display: none }

.section.news { background-color: #F6F6F6; padding-top: 20px; }
.input2 { height: 40px; padding: 0 14px; border: 1px #666; border-radius: 0; background-color: #fff; }

.login-btn {
	position: absolute;
	top: 0;
	right: 0;
	width: 44px;
	height: 40px;
	padding: 0;
	float: right;
	background-color: transparent;
	-webkit-transition: background-color .5s ease, color .5s ease;
	transition: background-color .5s ease, color .5s ease;
	color: #aaa;
	font-size: 15px;
	line-height: 20px;
	text-align: center
}

/* Menu dropdown toggle */

.w-dropdown { display: inline-block; z-index: 900; position: relative; text-align: left; margin-left: auto; margin-right: auto; }
.w-dropdown-btn, .w-dropdown-link, .w-dropdown-toggle { vertical-align: top; text-decoration: none; color: #fff; padding: 1px 15px 10px 15px; white-space: nowrap }
.w-dropdown-toggle { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; cursor: pointer; padding-right: 40px; }
.w-lightbox-backdrop, .w-nav-button { -webkit-tap-highlight-color: transparent; -moz-user-select: none; -ms-user-select: none; }

.w-dropdown-list { position: absolute; background: #fff; display: none; min-width: 100%; border: 1px solid #f7126d; }
.w-dropdown-list.w--open, .w-nav[data-collapse=all] .w-dropdown, .w-nav[data-collapse=all] .w-dropdown-toggle { display: block }
.w-dropdown-link { padding: 5px 0px; display: block; color: #222; }
.w-dropdown-link.w--current { color: #0082f3; }
.w-nav[data-collapse=all] .w-dropdown-list { position: static; }
.nav-link.nav-dropdown {
	/* change existing padding-right to this */
	padding-right: 30px;
}

.dropdown-icon {
	/* remove left setting, replace with right setting below */
	right: 10px;
	margin-right: 0;
}
/* Footer Properties - start */
.mid-footer { position: relative; float: left; width: 100%; padding-top: 5px; padding-bottom: 20px; font-weight: 300; color: #ffffff; }

footer h3 { font-size: 18px; line-height: 26px; letter-spacing: 0; text-transform: uppercase; color: #fff; font-weight: 400; }

.w-col { position: relative; float: left; width: 100%; min-height: 1px; padding-left: 10px; padding-right: 10px; }

.w-col-4 { width: 33.33333333%; }
.w-col-6 { width: 50%; 	padding-left: 0px; padding-right: 0px; }

.footerelementwrapper { padding-right: 20px; }

.bottomfooter {
	position: relative;
	float: left;
	width: 100%;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #111;
	color: rgba(255, 255, 255, 0.5);
	font-size: 12px;
	font-weight: 300;
	text-align: right;
}

.copyright { float: left; text-align: center; }

.social-icon-wrapper { float: none; }
.social-icon { display: inline-block; }

.social-link {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin-right: 7px;
	margin-top: 10px;
	padding-top: 8px;
	border-style: solid;
	border: solid 1px hsla(0,0%,100%,.6);
	border-radius: 100%;
	-webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
	transition: color .5s ease,background-color .5s ease,border .5s ease;
	line-height: 20px;
	text-align: center;
}

.social-link:hover { color: #f7126d; background-color: #fff; }

#cookie-bar { position: fixed; bottom: 0px; width: 100%; text-align: center; color: #fff; z-index: 1000; }
#cookie-bar a { color: #fff; display: inline-block; border-radius: 3px; text-decoration: none; margin-left: 8px; }
#cookie-bar p { margin-bottom: 0; }
/* Footer Properties - end */

.pf-sub-cats-listing h2 { font-size: 30px; margin-bottom: 25px; }
.pf-sub-cats-listing a { color: #666; }
.m-product-info { margin-top: 15px; text-align: center; }
.productwrapper:hover { box-shadow: 0px 2px 10px 0px rgba(0,0,0,.25); }
.productwrapper { transition: color .5s ease,opacity .5s ease,transform .6s ease,box-shadow .9s ease,-webkit-transform .6s ease; }

.w-col-9 { width: 75%; }
.w-inline-block { max-width: 100%; display: inline-block; } /* sets the product images to display inline - cat page */
.pf-product-list-item, .pf-recent-related-product-item {
	position: relative;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-top: 0px;
	background-color: #fff;
	color: #666;
	font-size: 14px;
	text-align: center;
	box-shadow: 0 0px 0px 0 rgba(78,78,78,.1);
}

.pf-related-recent-tab-content .pf-product-list-item, .pf-related-recent-tab-content .pf-recent-related-product-item {
	position: relative;
	width: 20%;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 0px;
	padding-right: 10px;
	padding-bottom: 0px;
	padding-left: 10px;
	background-color: #fff;
	color: #666;
	font-size: 14px;
	text-align: center;
	box-shadow: 0 0px 0px 0 rgba(78,78,78,.1);
	max-width: 250px;
}

.pf-product-list-item:hover { box-shadow: 0px 2px 10px 0px rgba(0,0,0,.25); }
.productwrapper { padding-bottom: 20px; margin-bottom: 5px; box-shadow: 0px 0px 0px 0px rgba(0,0,0,.15); border: solid 1px #eee; cursor: pointer; } /* set shadow and pointer on the product wrapper */
#pf-product-listing .w-col, #pf-related-recent-products-wrapper .w-col { padding-left: 8px; padding-right: 8px; } /* Padding for the products boxes on the category pages */
.pf-new-product-tag { top: 8px; right: 8px; } /* position of the product tags for the products boxes */
.filter-select-wrapper { margin-bottom: 10px; }

#prod-search-btn, .dropdown-toggle.pf-filter-item, .mid-footer { color: #fff; background-color: #f7126d; -webkit-appearance: none; border-radius: 0; }
input[type="submit"] { -webkit-appearance: none; border-radius: 0; }
.mid-footer { background-color: #aeabad !important; }
.pf-list-view-group-prods-wrapper{ margin-bottom:5px;}
.pf-list-view-group-prod{ margin:5px;}

/* Category and cart properties - start */
.pf-product-list-page { padding-top: 0px; }
#pf-sub-cats-listing.section { padding-bottom: 0px; }
#pf-product-listing { margin-bottom: 0px; }
#pf-product-list-page.section { padding-bottom: 0px; }
#pf-product-list-page.pf-page-wrapper { margin-bottom: 0px; }
.filter-dropdown { background-color: #f5f5f5; }
.filter-cats-sub { background-color: #ffffff; }

.errormessage-block { padding: 5px 10px; margin-bottom: 10px; margin-top: 10px; background-color: #fff; color: rgba(160,0,0,.8); text-align: center; border: 1px solid rgba(160,0,0,.8); }
.errormessage-block p { padding: 0; margin: 0; font-size: 15px; }

.filter-subdropdown-list .w-checkbox { display: block; }
/* Category properties - end */

/* Checkout properties - start */
#discount-btn { position: relative; padding: 4px 10px 7px 10px; border-radius: 0px 0px 0px 0px; border: solid 1px #ccc; color: #333; display: inline-block; }
.ecs-radio { display: block; margin-bottom: 5px; padding-left: 0px; }
label { display: block; margin-bottom: 5px; }
#billing, #delivery { padding-left: 0px; padding-right: 0px; }
textarea.w-input, textarea.w-select { height: auto; font-family: sans-serif !important; }
.pf-delivery-options-wrapper ul { padding-left: 0px; }
.pf-terms-wrapper a { color: #f7126d; }
.delivery-calendar-container { margin-left: 25px; padding-bottom: 10px; }
.pf-checkout-actions .ecs-row-1-2 { text-align: center; }
.pf-checkout-actions .ecs-row-1-2 #makePaymentBtn { max-width: 160px; }
.pf-checkout-cart-table td { padding: 3px; border: 1px solid #ccc; }
/* Checkout properties - end */

/* Category left hand properties - start */
.filter-subdropdown-list .filter-subdropdown-link { padding: 3px 0px 3px 10px; }
#filter .cat-selected { color: #f7126d; text-decoration: none; }
.pf-filter-item { padding: 10px 10px 10px 10px; }
.pf-filter-item a:hover { text-decoration: none; color: #f7126d; }
.filter-subdropdown-link .pf-filter-item { padding: 2px 0px 2px 10px; }
.pf-filter-title { padding: 0px 0px 5px 0px; }
.pf-filter-dropdown-list { padding-bottom: 0px; }
.pf-product-list-options select {
	float: left;
	width: auto;
	height: 33px;
	margin: 0 7px;
	border: 1px solid #ccc;
	display: block;
	padding: .35em 1.4em .5em .6em;
	max-width: 100%;
	box-sizing: border-box;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	background-color: #fff;
	background-image: linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: repeat;
	background-position: 0 0;
	background-size: 100%;
}
.pf-filter-wrapper { border: 1px solid #eee; }
.pf-btn.pf-btn-primary, .pf-load-more-btn { border: 1px solid #f7126d !important; color: #fff !important; background: #f7126d !important; cursor: pointer !important; }
.pf-btn.pf-btn-primary:hover, .pf-load-more-btn:hover { background-color: #fff !important; color: #f7126d !important; text-decoration: none !important; border-color: #f7126d !important; cursor: pointer !important; }
.pf-btn.pf-btn-with-qty { border-radius: 0 0px 0px 0; }
.product-info-match-height { padding-top: 15px; }
/* Category left hand properties - end */

a.pf-normal-link { text-decoration: none; }
.w--current { color: #f7126d !important; }
.mid-footer .w--current { color: #fff !important; }
.mid-footer.w--current a:hover { color: #666 !important; }
h1.productListingMainHeading { padding-top: 0px; margin-top: 0; }

/* Product page properties - start */
.w-tabs, .w-tab-pane, .w-tab-menu { position: relative; }
.w-tab-content { display: block; }
.w-col-2 { width: 16.66666667%; }
img { max-width: 100%; vertical-align: middle; display: inline-block; }
.pf-page-wrapper .pf-tab-header.w--current { color: #f7126d; }
.product-image { min-width: 100%; }
#pf-product-details-page .pf-product-image-thumb.w--current { border: 1px solid #f7126d; max-height: 182px; } /* border colour for the selected product image */
.w--tab-active { display: block !important; }
.w-tab-pane { display: none; }
.pf-product-main-image-wrapper img { width: 100%; }
.brand-filter { display: none; }
.pf-component-select-text { color: #999; }
#pf-product-actions-wrapper { padding: 15px 15px 15px 20px; border-radius: 20px; margin-bottom: 40px; border: 1px solid #ccc; }
a.pf-stock-notify-me-btn { color: #f7126d; text-decoration: none; }
a.pf-stock-notify-me-btn:hover { color: #f7126d; text-decoration: underline; }
span.pf-stock-notify-me-btn { color: #f7126d; text-decoration: none; }
.notification-image { position: absolute; padding-left: 10px; top: 40px; height: 70px; left: 0; }

/*.lobibox-close { color: black; }*/
.lobibox-notify-msg { padding-bottom: 10px; padding-top: 10px; max-height: none !important; font-size: 12px; }
/*.lobibox-notify-title { padding-top: 20px; }*/
.lobibox-notify-wrapper.top.right { right: 15px; top: 75px; }

#bag-opener-notification { padding-top: 6px; padding-bottom: 9px; text-transform: uppercase; min-width: 183px; height: 40px; letter-spacing: 1px; display: block; margin-top: 10px; }

.pf-group-prod:last-child { margin-right: 0px; }
.breadcrumb { margin-bottom: 13px !important; }

#pf-related-recent-products-wrapper { margin-top: 40px; position: relative; float: left; width: 100%; margin-bottom: 0; }
.pf-recent-related-product-item { box-shadow: 0 1px 0 1px rgba(0,0,0,.04); }
.pf-recent-related-product-item:hover {box-shadow: 0px 2px 10px 0px rgba(0,0,0,.25); }
.pf-related-recent-tab-content .pf-recent-related-product-item:hover {box-shadow: 0px 0px 0px 0px rgba(0,0,0,.25); }

.pf-product-main-image-wrapper:hover { cursor: zoom-in; }
.pf-qty-btn { width: 28px; cursor: pointer; }
.pf-qty-btn.pf-qty-btn-minus { border-right: 0px; }
.pf-qty-btn.pf-qty-btn-plus { border-left: 0px; margin-right: 0px; }

a.pf-icon-link:hover { color: #f7126d; text-decoration: none; }
.pf-product-images-slider-arrow { background-color: transparent; opacity: unset; }
#related-tab-content .w-icon-slider-left, #related-tab-content .w-icon-slider-right { top: 43%; }
#pf-related-recent-products-wrapper .w-icon-slider-left, #pf-related-recent-products-wrapper .w-icon-slider-right { top: 43%; }
.horizontal-scroll-box .w-icon-slider-left, .horizontal-scroll-box .w-icon-slider-right { top: 38% !important; color: #ccc; }
.productwrapper { min-height: 260px; }
.video-img { position: relative }
.video-btn { position:absolute; right:5px; bottom:1px; color:#fff; }
.pf-product-images-wrapper-slider .w-slider-arrow-left{
    left: 0px;
}
.pf-product-images-wrapper-slider .w-slider-arrow-right{
    right: 0px;
}
/* Product page properties - end */

/* Quick Order page properties - start */
.pf-quick-order-top .pf-input { margin-bottom: 0; display: inline-block; }
.pf-quick-order-quick-entry > input { width: 50%; }
.pf-btn.pf-btn-with-qty { border-left: none; vertical-align: top; }
.pf-btn.pf-btn-colour-one { background-color: #f7126d; color: #fff; }
.pf-btn.pf-btn-colour-one:hover { background-color: #fff; color: #f7126d; text-decoration: none; border-color: #f7126d; }
.fa-search { padding: 0px 0px 0px 0px; }
.pf-btn.pf-btn-with-icon { font-size: 13px; padding: 10px; text-align: center; z-index: 10; }
.pf-input-with-btn-wrapper .pf-btn.pf-tbx-btn { border-radius: 0 0px 0px 0; }
.pf-quick-order-top { position: relative; float: left; width: 100%; margin-top: 0px; padding-bottom: 5px; }
/* Quick Order page properties - end */

/* Account page properties - start */
.pf-tabs-wrapper .pf-tabs-top { border-bottom: 1px solid #ccc; }
.w-tab-menu .pf-tab-header { background-color: #fff; border-radius: 10px 10px 0 0; width: auto; display: inline-block; border: 1px solid #ccc; color: #666; border-bottom: none; }
.w-tab-menu .pf-tab-header.w--current { background-color: #f7126d; color: #fff !important; position: relative; top: 1px; border: 1px solid #f7126d; border-bottom: none; }
.pf-table thead tr { border-bottom: 1px solid #ccc; }
table { border-collapse: collapse; border-spacing: 0; }
#info-tab-content .ecs-row p { margin-bottom: 3px; }
.shipmentTable, .pf-order-details-table {
    table-layout: fixed;
}
/* Account page properties - end */
.banner {
    position: relative;
    float: left;
    width: 100%;
    height: 360px;
    background-color: #ddd;
    text-align: center;
}
.banner-container { color: #fff; font-size: 19px; line-height: 27px; height: 100%; display: flex !important; align-items: center; text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); }
.banner-container h1 {flex: 1 100%; padding-bottom: 0px; }
.featured-products-title { text-align: center; }
.contact-bg { position: absolute; left: 0; right: 0; bottom: 0; top: 0; background-position: 0 0,50% 50%; background-size: auto,cover; background-repeat: repeat,no-repeat; }
.banner { background-position: 50% 50%; background-size: cover; background-repeat: no-repeat; }

.w-input, .w-select {
	display: block;
	width: 100%;
	height: 38px;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333;
	vertical-align: middle;
	background-color: #fff;
	border: 1px solid #ccc;
}

#top-cart-below , #top-cart-title{ padding: 0px 10px 0px 10px; }
#top-cart-below .proceed { margin-top: 14px; margin-bottom: 10px; }
.cart-buttons-popup { margin-top: 0px; }

/* New row and column properties */
.ecs-row-top { padding-top: 186px; }
.ecs-row-container { position: relative; float: left; width: 100%; padding-left: 20px; padding-right: 20px; }
.ecs-row-full-width { position: relative; float: left; width: 100%; }
.ecs-main-container { position: relative; float: left; width: 100%; padding-top: 146px; padding-bottom: 50px; }
.ecs-main-container-nbp { position: relative; float: left; width: 100%; padding-top: 150px; padding-bottom: 0px; }
.ecs-main-container-no-padding { padding-top: 186px; overflow: hidden; }
.ecs-bottom-container { padding-left: 20px; padding-right: 20px; }
.ecs-row { position: relative; width: 100%; max-width: 1200px; margin: auto; display: table; }
.ecs-row-1-2 { position: relative; width: 50%; max-width: 1200px; margin: auto; display: table; }
.ecs-row-site-padding { padding-left: 20px; padding-right: 20px; }
.ecs-row-margin-bottom { margin-bottom: 30px; }

.blog-list { padding-top: 30px; }
.blog-list-description { width: 100%; padding-top: 10px; padding-bottom: 40px; text-align: center; }
.blog-list .ecs-column-2:nth-child(2) { margin-right: 0 !important; }

.ecs-column-2, .ecs-column-2-full, .ecs-column-3, .ecs-column-3-margin-1, .ecs-column-3-2, .ecs-column-4, .ecs-column-4-3, .ecs-column-6 { position: relative; float: left; margin-right: 5.5%; }
.ecs-column-6 { margin-right: 2%; }
.ecs-column-2 { width: 47.25%; }
.ecs-column-2-full { width: 47.25%; margin-right: 0; }
.ecs-column-3 { width: 29.667%; }
.ecs-column-4 { width: 23%; margin-right: 1%; }
.ecs-column-4-3 { width: 74%; margin-right: 3%; }
.pf-product-listing .ecs-column-3 { width: 31.667%; margin-right: 2.5%; margin-top: 0.4%; margin-bottom: 0.4%; }
.pf-product-listing .ecs-column-4 { width: 23%; margin-right: 2%; }
.pf-product-listing .ecs-column-4-3 { width: 75%; margin-right: 0%; }

.mega-list .ecs-column-4 { width: 23%; margin-right: 1%; margin-left: 1%; }
.ecs-column-3-2 { margin-right: 1.5%; width: 65%; }
.ecs-column-3-margin-1 { width: 32.667%; margin-right: 1%; margin-bottom: 1%; }
.ecs-column-6 { width: 14.98%; }

.ecs-column-2:last-child { margin-right: 0 !important; }
.ecs-column-3:last-child { margin-right: 0 !important; }
.ecs-column-3:nth-child(3n + 3) { margin-right: 0 !important; }
.ecs-column-3-margin-1:last-child { margin-right: 0 !important; }
.ecs-column-3-margin-1:nth-child(3) { margin-right: 0 !important; }
.ecs-column-4:last-child { margin-right: 0 !important; }

.ecs-column-6:last-child { margin-right: 0 !important; }

.flex-item { display: flex; flex-wrap: wrap; }
#pf-product-listing .flex-col { width: 32%; margin: 0.5% 1% 0% 1%; }
#pf-product-listing .flex-col:nth-child(3n + 3), #pf-product-listing .flex-col:last-child { margin-right: 0; }
#pf-product-listing .flex-col:nth-of-type(3n) { margin-right: 0%; }
#pf-product-listing .flex-col:nth-of-type(3n+1) { margin-left: 0%; }
.featured-products .flex-col { width: 24.25%; margin: 0.5% 1% 0% 0%; }
.featured-products .flex-col:nth-child(3n+4), .featured-products .flex-col:last-child { margin-right: 0%; }
.featured-products .flex-col:last-of-type { margin-right: 0%; }
.featured-products .flex-col:first-of-type { margin-left: 0%; }

#pf-sub-cats-listing .flex-col { width: 15%; margin: 0.5% 1% 0% 1%; }
#pf-sub-cats-listing .flex-col:nth-child(6n + 6), #pf-product-listing .flex-col:last-child { margin-right: 0; }
#pf-sub-cats-listing .flex-col:nth-of-type(6n) { margin-right: 0%; }
#pf-sub-cats-listing .flex-col:nth-of-type(6n+1) { margin-left: 0%; }

.text-align-left { text-align: left; }
.text-align-right { text-align: right; }
.text-align-center { text-align: center; }

.w-icon-nav-menu { display: none; }
.mbss-listItem-text p { padding-top: 20px; }
.bottomfooter p { padding-top: 10px; }


.w-tab-link { display: inline-block; padding: 9px 30px; text-align: left; cursor: pointer; color: #222; background-color: #ddd; }

/* Blog layour properties - start */
.blogposts-block { padding-top: 50px; padding-bottom: 50px; position: relative; float: left; width: 100%; }
.blog-img-small._1 { background-size: cover; }
.blog-img-small._1, .blog-img-small._2 { background-position: 50% 50%; background-repeat: no-repeat; }
.blog-img-small { display: block; padding-bottom: 100%; }
.blogpost-caption div, .blogpost-hover div, .blogpost-text div { display: inline-block; }
.blog-element-wrapper { display: none; }
.blogpost-text h3 { margin-top: 0px; font-weight: normal; }

a.categorylink { color: #f7126d; }
.tag {
	display: inline-block;
	background-color: #fff;
	color: #f7126d;
	text-decoration: none;
	border-color: #f7126d !important;
	margin-right: 7px;
	margin-bottom: 7px;
	padding: 10px 20px;
	border: 1px solid #666;
	-webkit-transition: background-color .5s ease,color .5s ease;
	transition: background-color .5s ease,color .5s ease;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
}

.tag:hover { border: 1px solid #fff; color: #fff; background: #f7126d; }
.w-list-unstyled { padding-left: 0; list-style: none; }
.blogcategories li { list-style: none; }
.list-item { margin-top: 2px; margin-bottom: 2px; line-height: 21px; }
.list-item, .product-price { font-weight: 400; }
.blog-details { margin-top: 25px; }
.category-blog { margin-bottom: 30px; }
.contact-text a{ color: #666; }
.contact-text a:hover { color: #f7126d; }
.pf-form-confirm-text { margin-top: 10px; margin-bottom: 10px; }
.pf-btn.pf-btn-primary { border: 1px solid #fff; color: #fff; background: #f7126d; margin-top: 0px; margin-bottom: 0px; }
#wf-form-Secure-Login-Form .pf-btn.pf-btn-primary { margin-top: 0px; margin-bottom: 0px; }
a.pf-normal-link:hover { color: #f7126d; }
.w-checkbox, .w-radio { display: inline-block; margin-bottom: 5px; padding-left: 20px; }
.w-form-label { display: inline-block; cursor: pointer; font-weight: 400; margin-bottom: 0; }
.w-checkbox-input, .w-radio-input { margin: 4px 0 0 -20px; line-height: normal; float: left; }
#create-btn.pf-btn.pf-btn-primary { margin-top: 0px; margin-bottom: 0px; }

/* Blog layour properties - end */

#recently-tab-content { width: 100%; }
.pf-quick-order-top .w-row .ecs-column-3:first-of-type { width: 35%; margin-right: 1%; margin-bottom: 10px; }
.pf-quick-order-top .w-row .ecs-column-3 { width: 32%; margin-right: 0%; margin-bottom: 10px; }
.pf-quick-order-cart-footer { padding: 20px 0; /*text-align: center;*/ clear:both; text-align: right; }
.pf-my-orders-table .pf-btn.pf-btn-primary, .pf-my-returns-table .pf-btn.pf-btn-primary, .pf-my-statement-table .pf-btn.pf-btn-primary { border: 0px solid #f7126d !important; color: #f7126d !important; background: transparent !important; cursor: pointer !important; padding-top: 8px; }
.pf-my-orders-table .pf-btn.pf-btn-xs { padding: 8px 15px 0px 0px; }
.pf-my-statement-table .pf-btn.pf-btn-xs { padding: 0 15px 0 0; height: auto; }
.table-holder, .pf-checkout-cart-loader { max-width: 1200px !important; }
.cart-table th { white-space: normal; }

.pf-my-orders-table, .pf-my-returns-table { border: 1px solid #ccc; width: 100%; margin:0; padding:0; border-collapse: collapse; border-spacing: 0; }
.pf-my-orders-table tr, .pf-my-returns-table tr { padding: 2px 0px 2px 0px; background: transparent; }
.pf-my-orders-table th, .pf-my-orders-table td { padding: 10px; text-align: left; }
.pf-my-returns-table th, .pf-my-returns-table td { padding: 10px; text-align: center; }
.pf-my-orders-table th, .pf-my-returns-table th { text-transform: uppercase; letter-spacing: 1px; }
.pf-order-details-table .pf-order-details-total-label { text-align: right; }
.pf-order-actions, .pf-order-item-actions { white-space: nowrap; }
.pf-order-details-summary-table td { padding: 5px; }
.pf-my-orders-table .order-details-row > td { padding: 0; }

#pf-product-details-page .pf-product-image-thumb { margin-bottom: 2%; }
.pf-quick-order-top .w-row .pf-qty-wrapper { position: absolute; top: 0; right: 0; }
.top-nav-links { position: relative; float: right; padding-left: 5px; }
.flip-images-wrapper .flex-child {
	width: 48%;
}
#pf-gallery-image-products {
	display:flex;
}
#pf-gallery-image-products .flex-col {
	padding:10px;
}
.AboutList {
	float: left;
	width: 100%;
}
#pf-multi-select-slider {
	margin-right:0;
	width:77%;
}
iframe[id^="PIN_"] {
	position: fixed!important;
}
.subscribe-wrapper .w-input {
	display:inline-block;
	width:50%;
}
.subscribe-wrapper .pf-btn-primary {
	display: inline-block;
	margin-left: 5px;
	padding-bottom: 7px;
	padding-top: 7px;
	line-height: 27px;
	height: 38px;
}

.pf-my-statement-account-balance { margin-top: 0; }
.pf-my-statement-overdue-amount { color: red; }
.pf-my-statement-alert { text-align: center; color: white; padding: 5px; margin-bottom: 15px; }
.pf-my-statement-alert-warning { background: #EE9A2F; }
.pf-my-statement-alert-danger { background: #EE2F2F; }
.pf-my-statement-alert > i { vertical-align: middle; }
.pf-my-statement-alert > span { vertical-align: middle; padding-left: 15px; }

/* media query for smaller phones like the iPhone 5- start */
@media screen and (max-width:380px) {
	.brand img { max-width: 75%; height: auto; padding-top: 9px; padding-bottom: 6px; }
	.pf-promotion-strip-enabled .ecs-main-container-nbp { padding-top: 125px; } /* Adjust padding when promotional strip is enable*/
	.cart-buttons-popup .pull-right, .cart-buttons-popup .pull-left { float: none; width: 100%; }
	.ecs-row-container { padding-left: 10px; padding-right: 10px; }
}

/* media query for medimum phones like the iPhone 6 - start */
@media screen and (max-width:479px) {
	.searchform .w-input { width: 100% !important; }
	.top-nav-links { display: none; }
	.pf-product-listing .ecs-column-4-3 { width: 100% !important; margin-right: 0%; }
	#pf-product-listing .ecs-column-3 { width: 100% !important; }
	#pf-product-listing .ecs-column-4 { width: 100% !important; margin-right: 0%; }
	#pf-sub-cats-listing .ecs-column-6 { width: 100% !important; margin-right: 0%; margin-bottom: 5% !important; }
	.pf-product-listing .ecs-column-3 { margin-top: 2% !important; margin-bottom: 2% !important; }

	.pf-quick-order-top .w-row .ecs-column-3 { width: 100% !important; margin-right: 0%; margin-bottom: 10px; }
	
	#pf-product-listing .flex-col { width: 100% !important; margin: 0.5% 0% 1% 0% !important; }
	#pf-product-listing .flex-col:nth-child(2n+1), #pf-product-listing .flex-col:last-child { margin-right: 0% !important; }
	#pf-product-listing .flex-col:last-of-type { margin-right: 0% !important; }
	#pf-product-listing .flex-col:first-of-type { margin-left: 0% !important; }
	.featured-products .flex-col { width: 100% !important; margin: 0.5% 0% 1% 0% !important; }
	.featured-products .flex-col:nth-child(2n+1), .featured-products .flex-col:last-child { margin-right: 0% !important; }
	.featured-products .flex-col:last-of-type { margin-right: 0% !important; }
	.featured-products .flex-col:first-of-type { margin-left: 0% !important; }
	#pf-sub-cats-listing .flex-col { width: 100% !important; margin: 0.5% 0% 4% 0% !important; }
	#pf-sub-cats-listing .flex-col:nth-child(2n+1), #pf-product-listing .flex-col:last-child { margin-right: 0% !important; }
	#pf-sub-cats-listing .flex-col:last-of-type { margin-right: 0% !important; }
	#pf-sub-cats-listing .flex-col:first-of-type { margin-left: 0% !important; }
	
	.pf-popup-cart-loader { padding-left: 10px; padding-right: 10px; }
	#cartModal { padding: 20px 0px; }
	.pf-proceed-checkout-cart, .pf-continue-shopping-cart { margin-right: 0px; width: auto; margin-bottom: 10px !important; }
	.pf-continue-shopping-cart { margin-bottom: 0px !important; }
	/*.cart-buttons-popup .pull-right, .cart-buttons-popup .pull-left { float: none; }*/
	.pf-quick-order-quick-entry > input { width: 40%; }
	.pf-quick-order-top { padding: 10px; }
	.pf-quick-order-top .w-row .ecs-column-3 { width: 100%; margin-right: 0%; }
	/*.pf-qty-btn { width: 22px; }*/
	.pf-my-orders-table .pf-btn.pf-btn-xs { padding: 1px 15px 0px 0px; }
	.pf-quick-order-cart-footer { text-align: center !important; }

	.pf-my-orders-table .pf-btn.pf-btn-primary { border: 0px solid #f7126d !important; color: #f7126d !important; background: transparent !important; cursor: pointer !important; padding-top: 0px; }
	.cart-table tr td:nth-child(1) { width: 40%; padding: 1px; }
	.ecs-row-container.cart-buttons-popup { padding-left: 10px; padding-right: 10px; }
	#checkout .ecs-row h3 { font-size: 23px; text-align: center; }
	#pf-discount-input-wrapper { margin: 0 auto; }
	#billing-manual-wrapper .note-text { text-align: center; width: 75%; margin: 0 auto; }
	.paymentLabel { margin-bottom: 10px; }

    .pf-slide-item{
        max-width:100%;
        margin-left:5px;
        margin-right:5px;
    }
}

@media screen and (max-width:512px) {
	.pf-related-recent-tab-content .pf-product-list-item, .pf-related-recent-tab-content .pf-recent-related-product-item { width: 100% !important; }
	.ecs-row-full-width.blog-list .ecs-column-2 { width: 100% !important; }
	.ecs-row-full-width.blog-list .ecs-column-2 { margin-right: 0% !important; }
	.ecs-row-full-width.blog-list .ecs-column-2:nth-child(2) { margin-right: 0% !important; }
	.ecs-row-full-width.blog-list .ecs-column-2.blogPostStackIpad h1 { display: none; }
}

@media screen and (min-width:768px)
{
	.shrink .topnav { height: 21px; }
	.shrink .topnav .nav-link.mid { padding: 2px 10px 2px 10px; }
	.shrink .brand { padding-top: 5px; padding-bottom: 10px; }
	.shrink .searchform-wrapper { margin: 10px auto 0; max-height: 45px; }
	.shrink .bag-opener.nav-link.mid, .shrink .nav-link.mid { padding: 22px 0px 25px 10px; }
	.shrink .nav-link { padding-top: 10px; padding-bottom: 12px; }
	.shrink .nav-link.nav-dropdown { padding-top: 1px; padding-bottom: 10px; }
	.shrink .nav-link.nav-dropdown.w--open { padding-top: 1px !important; }
	.shrink .mega-list.w--open { margin-top: 0px; }
	#search-form { display: block !important; }
    .sidenav a:last-of-type{
        padding-bottom:100px;
    }
}

/* media query for phones just before the iPad break point at 768px - start */
@media screen and (max-width:767px) {
	/*.ecs-row { padding-left: 5%; padding-right: 5%; }*/
	.mega-list.w--open {
		border: 0px solid #f7126d;
	}

	.pf-promotion-strip-enabled .ecs-main-container-nbp {
		padding-top: 125px;
	}
	/* Adjust padding when promotional strip is enable*/
	.filter-select-wrapper .ecs-column-2 {
		width: auto !important;
		padding-top: 5px;
	}

	.w-dropdown-list {
		transform: none !important;
		border: 0px !important;
	}

	#search-form {
		display: none;
	}

	.ecs-main-container {
		padding-top: 100px !important;
	}

	.pf-promotion-strip-enabled .ecs-main-container {
		padding-top: 120px !important;
	}
	/* Adjust padding when promotional strip is enable*/
	.ecs-main-container-nbp {
		padding-top: 100px !important;
	}

	.topnav .nav-link.mid {
		padding: 2px 5px 5px 12px;
	}

	.top-nav-links {
		display: none;
	}

	.nav {
		background-color: #fff;
	}

	.fa-search {
		right: 12px;
	}

	.brand {
		width: 100% !important;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.searchform-wrapper.w-form {
		display: none;
	}


	/* mobile nav */
	.midnav {
		display: flex;
	}

	.mobile-flex-col {
		width: 10%;
	}

	.mobile-flex-col-logo {
		width: 80%;
	}

	.menu-button {
		border: 0px solid #ddd;
		color: #f7126d;
		font-size: 32px;
		cursor: pointer;
		margin-top: 15px;
	}

	.mobile-search-icon .fa-search {
		float: none;
		top: auto;
		right: auto;
		z-index: auto;
		margin-top: 23px;
	}

	.midnav .ecs-row {
		text-align: center;
	}

	.mobile-search-icon {
		display: inline-block;
	}

		.mobile-search-icon .fa-search {
			color: #f7126d;
			font-size: 1.8em;
		}

	.nav-menu.top {
		float: none;
		display: inline-block;
	}

	.mobile-search-icon button {
		font-size: 1em;
		border: 0;
		background-color: #fff;
		padding-left: 0px;
		cursor: pointer;
	}

	/* end mobile nav */

	#pf-product-details-page .ecs-row .ecs-column-2 {
		width: 100%;
	}

	.pf-quick-order-cart-footer {
		text-align: center !important;
	}

	.ecs-column-2 {
		width: 100%;
	}

	#pf-product-list-page .ecs-row .ecs-column-2.ecs-column-2:last-child {
		width: 100%;
		margin-right: 0%;
	}

	.banner {
		height: 240px;
	}

	.mega-list .w-row .w-col-3 {
		width: 100% !important;
	}

	.mega-list.w--open {
		position: relative !important;
		overflow: hidden;
		width: 100%;
		margin-left: 0px;
		left: 0 !important;
		transform: none !important;
		top: 0 !important
	}

	.mega-list.w--open {
		display: block;
		max-width: 768px;
		margin-left: 0px;
	}

	.mega-list.w--open {
		right: auto;
		width: 940px;
		margin-top: -3px;
		padding-right: 10px;
		padding-bottom: 10px;
		padding-left: 10px; /*background-color: hsla(0, 0%, 100%, .9);*/
	}

	.mega-list .ecs-column-4 {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
	}

	.w-dropdown-link, .w-dropdown-toggle {
		padding: 5px 15px 5px 20px;
		white-space: inherit;
	}

	.dropdown-icon {
		padding: 10px 15px 10px 0;
		width: auto;
	}

	.w-dropdown-toggle {
		padding: 10px 15px 10px 20px !important;
	}

	.ecs-column-4 {
		width: 100%;
		margin-right: 0;
		text-align: center;
	}

	.ecs-column-4-3 {
		width: 100%;
		margin-right: 0%;
		text-align: center;
	}

	.ecs-column-4 .pf-filter-items, .ecs-column-4 .pf-filter-heading {
		text-align: left;
	}

	.mid-footer {
		font-size: 18px;
	}

		.mid-footer h3 {
			color: #666;
			margin-top: 30px;
			margin-bottom: 10px;
		}

		.mid-footer .ecs-column-2, .mid-footer .ecs-column-3 {
			width: 100%;
			text-align: center;
		}

			.mid-footer .ecs-column-2 a, .mid-footer .ecs-column-3 a {
				display: inline-block;
				margin: 4px 0px 4px 0px;
			}

	.bottomfooter .ecs-column-2 {
		width: 100%;
		text-align: center;
	}

		.bottomfooter .ecs-column-2 p {
			margin: 5px 0px 5px 0px;
		}

	.searchform-wrapper {
		position: relative;
		display: block;
		max-width: 100%;
		padding-top: 0;
		margin: -5px auto 0;
		clear: both;
	}

	.searchform .w-input {
		width: 100%;
	}

	#prod-search-btn {
		right: 0px;
	}

	.midnav {
		height: auto;
	}

	.nav {
		position: relative;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.w-nav-overlay {
		overflow-y: auto;
	}

	.w-nav[data-collapse="small"] .w-nav-button {
		display: block;
	}

	.nav-link.mid, .nav-link.top {
		display: inline-block;
		position: relative;
		float: left !important;
		width: auto;
	}

	[class^="w-icon-"] {
		font-family: webflow-icons;
		speak: none;
		font-style: normal;
		font-weight: 400;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#bag-opener {
		position: absolute;
		top: -29px;
		right: -35px !important;
	}

		#bag-opener.nav-link.mid {
			padding: 0px 0px;
			top: -25px;
		}

	.brand {
		position: relative;
		float: left;
	}

	.w-icon-nav-menu {
		display: block;
		cursor: pointer;
	}

	.w-icon-dropdown-toggle {
		position: absolute !important;
		top: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		margin: auto 20px auto auto !important;
		width: 1em !important;
		height: 1em !important;
	}

	.w-nav[data-collapse="small"] .w-dropdown-list {
		position: static;
	}

	.w-nav[data-collapse="small"] .w-nav-menu {
		display: none;
	}

	.w-nav[data-collapse="small"] .w-nav-button {
		display: block;
	}

	.w-nav-button {
		position: relative;
	}

	.nav-menu {
		background-color: rgba(68,68,68,.9);
	}

	.w-nav-overlay {
		position: absolute;
		display: none;
		top: 100%;
		left: 0;
		right: 0;
		width: 100%;
		overflow-y: auto;
		height: 1400px !important;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}

	.w--nav-menu-open {
		display: block !important;
		position: absolute;
		left: 0;
		right: 0;
		overflow: visible;
		min-width: 320px;
	}

	.w--nav-link-open {
		display: block;
		position: relative;
	}

	.nav-link {
		padding-top: 14px;
		padding-bottom: 14px;
		padding-left: 21px;
		font-size: 18px;
		line-height: 20px;
		width: 100%;
		text-align: left;
	}

	.w-nav-link {
		display: inline-block;
		color: #fff;
		padding: 10px 20px 10px 20px;
		text-align: left;
		margin-left: auto;
		margin-right: auto;
	}

	.w-dropdown {
		position: relative;
		text-align: left;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}

	.shrink .nav-link {
		padding-top: 10px;
		padding-bottom: 10px;
	}

		.shrink .nav-link.nav-dropdown {
			padding-top: 10px !important;
		}

	.slider {
		height: 40vh;
		max-height: 400px;
		min-height: 358px;
	}
	/* Reduce the height of the main slider for mobile */
	.w-slider-dot {
		border: solid 1px #ccc;
	}

		.w-slider-dot.w-active {
			background-color: #fff;
			border: solid 1px #fff;
		}

	.w-slider {
		position: relative;
		height: 300px;
		text-align: center;
	}

	.title-wrapper h2 {
		padding-top: 0px;
		font-size: 24px;
		line-height: 40px;
	}

	.title-wrapper {
		margin-bottom: 20px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.banner-text-large {
		font-size: 21px;
		line-height: 29px;
	}
	.banner-text-medium {
		font-size: 21px;
		line-height: 29px;
	}
	.banner-text-small {
		font-size: 19px;
		line-height: 29px;
	}
	.w-col-tiny-6 {
		width: 100%;
	}

	.home-element-wrapper {
		width: 100%;
		margin-bottom: 25px;
	}

	h2 {
		font-size: 24px;
		line-height: 32px;
	}

	.center-flex h2 {
		line-height: 63px;
	}

	.flex-child, .flex-child.small, .flex-child.extra-small {
		width: 100%;
		margin-bottom: 10px;
		margin-right: 0%;
		margin-left: 0%;
	}

	.productwrapper {
		display: block;
		max-width: 350px;
		margin-right: auto;
		margin-bottom: 0;
		margin-left: auto;
		color: #666;
	}

	.img-overlay {
		height: 300px;
	}

	.section.news .w-col-4 {
		width: 100%;
	}

	.AboutList img {
		height: 100%;
		width: 100%;
	}

	.product-detail-slider-arrow {
		color: #eee;
	}

	.w-col, .w-col-3, .w-col-4, .w-col-9 {
		width: 100% !important;
		padding-left: 0px;
		padding-right: 0px;
		text-align: center;
	}

	.ecs-column-3 {
		width: 100%;
	}

	.ecs-column-3-2 {
		margin-right: 0%;
		width: 100%;
	}

		.ecs-column-3-2 .blogpost-text {
			padding: 20px 20px 40px 20px;
			text-align: center;
		}

	#pf-product-listing .flex-col {
		width: 48.5%;
		margin: 0.5% 0% 0.5% 0%;
	}

		#pf-product-listing .flex-col:nth-child(2n+1), #pf-product-listing .flex-col:last-child {
			margin-right: 3%;
		}

		#pf-product-listing .flex-col:last-of-type {
			margin-right: 0%;
		}

		#pf-product-listing .flex-col:first-of-type {
			margin-left: 0%;
		}

	.featured-products .flex-col {
		width: 48.5%;
		margin: 0.5% 0% 1% 0%;
	}

		.featured-products .flex-col:nth-child(2n+1), .featured-products .flex-col:last-child {
			margin-right: 3%;
		}

		.featured-products .flex-col:last-of-type {
			margin-right: 0%;
		}

		.featured-products .flex-col:first-of-type {
			margin-left: 0%;
		}

	#pf-sub-cats-listing .flex-col {
		width: 48.5%;
		margin: 0.5% 0% 2% 0%;
	}

		#pf-sub-cats-listing .flex-col:nth-child(2n+1), #pf-sub-cats-listing .flex-col:last-child {
			margin-right: 3%;
		}

		#pf-sub-cats-listing .flex-col:last-of-type {
			margin-right: 0%;
		}

		#pf-sub-cats-listing .flex-col:first-of-type {
			margin-left: 0%;
		}

	#pf-popup-cart-wrapper h3 {
		font-size: 23px !important;
	}

	.pf-related-recent-tab-content .pf-product-list-item, .pf-related-recent-tab-content .pf-recent-related-product-item {
		width: 50.4%;
	}

	.pf-quick-order-top .w-row .ecs-column-3 {
		width: 100% !important;
		margin-right: 0%;
		margin-bottom: 10px;
	}

	.w-tab-menu .pf-tab-header {
		border-radius: 0px 0px 0 0;
		width: 100%;
		border-bottom: 1px solid #ccc;
		text-align: center;
		border-top: 1px solid #ccc;
		margin-top: 1px;
		margin-bottom: 1px;
	}

		.w-tab-menu .pf-tab-header.w--current {
			border-bottom: solid 1px #ccc;
			margin-bottom: 1px;
			margin-top: 0;
		}

	.pf-tabs-wrapper .pf-tabs-top {
		border-bottom: 0px solid #ccc;
	}

	#pf-related-recent-products-wrapper .pf-tab-menu {
		border-bottom: 0px solid #ccc;
	}

	#addresses-wrapper .ecs-row {
		padding-left: 0%;
		padding-right: 0%;
	}

	.pf-btn.pf-btn-sm {
		font-size: 15px;
	}

	.blogpost-caption {
		padding-right: 10px;
		padding-left: 10px;
	}

	.pf-product-details-info-wrapper h1 {
		font-size: 30px;
	}

	#pf-product-details-page .pf-product-images-wrapper-slider {
		display: grid;
		margin-bottom: 10px;
	}

	.pf-product-details-description {
		margin-bottom: 40px;
	}

	.pf-product-listing .ecs-column-4-3 {
		width: 100%;
		margin-right: 0%;
	}

	#pf-product-listing .ecs-column-3 {
		width: 49%;
		margin-right: 1.5%;
	}

		#pf-product-listing .ecs-column-3:nth-child(2n) {
			margin-right: 0%;
		}

		#pf-product-listing .ecs-column-3:nth-child(2n + 3) {
			margin-right: 1.5% !important;
		}

	#pf-product-listing .ecs-column-4 {
		width: 100%;
	}

	#pf-sub-cats-listing .ecs-column-6 {
		width: 49%;
		margin-right: 2%;
		margin-bottom: 2%;
	}

		#pf-sub-cats-listing .ecs-column-6:nth-child(2n) {
			margin-right: 0%;
		}

	.pf-product-listing .ecs-column-3 {
		margin-top: 0.5%;
		margin-bottom: 0%;
	}

	.productwrapper {
		min-height: 230px;
	}

	.pf-quick-order-top .w-row .ecs-column-3 {
		width: 100%;
		margin-right: 0%;
	}

	.pf-my-orders-table .pf-btn.pf-btn-primary {
		border: 0px solid #f7126d !important;
		color: #f7126d !important;
		background: transparent !important;
		cursor: pointer !important;
		padding-top: 0px;
	}

	.pf-my-orders-table .pf-btn.pf-btn-xs {
		padding: 1px 15px 0px 0px;
	}

	.pf-all-wishlists tr.pf-show-mobile {
		display: table-row;
		text-align: center;
	}

	.pf-all-wishlists .pf-favs-table td {
		padding: 10px 5px 10px 0px !important;
		text-align: center;
		width: 100%;
		display: block;
	}

	.pf-hide-mobile {
		display: none !important;
	}

	.pf-all-wishlists .pf-favs-table td:nth-child(2n+5) {
		text-align: right !important;
	}

	.pf-add-wishlist-to-cart-btn.pull-right {
		float: none;
		text-align: center;
	}
	/*	.pf-all-wishlists .pf-favs-table td:first-of-type { display: block; }*/
	/*	.fav-item-img-mobile { position: relative; float: left; width: 100%; display: block; } */

	.pf-my-orders-table, .pf-my-returns-table {
		border: 0;
	}

		.pf-my-orders-table thead, .pf-my-returns-table thead {
			display: none;
		}

		.pf-my-orders-table tr, .pf-my-returns-table tr {
			margin-bottom: 15px;
			display: block;
			border-bottom: solid 1px #ccc;
		}

		.pf-my-orders-table .core-hidden, .pf-my-returns-table .core-hidden {
			margin-bottom: 0px;
			display: none;
			border-bottom: none;
		}

		.pf-my-orders-table td, .pf-my-returns-table td {
			display: block;
			text-align: right;
			font-size: 13px;
			line-height: 20px;
			min-height: 20px;
		}

			.pf-my-orders-table td:last-child {
				border-bottom: 0;
			}

			.pf-my-orders-table td::before {
				content: attr(data-label);
				float: left;
				text-transform: uppercase;
				font-weight: bold;
			}

		.pf-my-orders-table tbody {
			line-height: 0 !important;
		}

	.core-hidden .order-details-row {
		display: none;
	}

	.pf-order-details, .pf-return-details {
		padding: 10px;
		border: 1px solid #ccc;
		margin-bottom: 10px !important;
	}

		.pf-order-details table td, .pf-return-details table td {
			padding: 0px 5px !important;
		}

	.pf-order-details-table .pf-order-details-total-label {
		display: none;
	}

	.pf-order-details-table tr:last-child, .pf-return-details-table tr:last-child {
		margin-bottom: 0;
		border-bottom: none;
	}

	.pf-order-return-table, #pfQoSearchResults {
		border: 0;
		margin-bottom: 20px;
	}

		.pf-order-return-table thead, #pfQoSearchResults thead {
			display: none;
		}

		.pf-order-return-table tr, #pfQoSearchResults tr {
			margin-bottom: 0px;
			display: block;
			border-top: solid 1px #ccc !important;
			padding-bottom: 20px;
		}

		.pf-order-return-table .pf-my-orders-table .core-hidden, #pfQoSearchResults .core-hidden {
			margin-bottom: 0px;
			display: none;
		}

		.pf-order-return-table td, #pfQoSearchResults td {
			display: block;
			text-align: right;
			font-size: 13px;
			line-height: 20px;
		}

			.pf-return-details-table td:first-of-type, #pfQoSearchResults td:first-of-type {
				padding-top: 10px !important;
				padding-bottom: 10px !important;
			}

			.pf-order-return-table td:last-child, .pf-my-returns-table td:last-child, #pfQoSearchResults td:last-child {
				border-bottom: 0;
			}

			.pf-order-return-table td::before, .pf-my-returns-table td::before, #pfQoSearchResults td::before {
				content: attr(data-label);
				float: left;
				text-transform: uppercase;
				font-weight: bold;
			}

	.pf-order-return-qty {
		width: 10%;
		min-width: 40px;
	}

	.pf-quick-order-wrapper #pfQoSearchResults.pf-table tr {
		border: 0px solid #ccc;
	}

	.pf-quick-order-results-wrapper {
		overflow-x: inherit;
	}

	#pfQoSearchResults .pf-qo-select-options, .pf-platform-modal-text .pf-btn.pf-add-to-cart-btn {
		max-height: 34px;
	}

	.pf-qty-wrapper {
		margin-bottom: 10px;
	}

	.pf-modal-wishlist-image img {
		margin-bottom: 10px;
	}

	.ecs-column-2.footer-links {
		max-width: 100%;
		padding-right: 0%;
	}

	.nav-menu.top .w-nav-link span {
		font-size: 12px;
	}

	.flip-images-wrapper .flex-child {
		width: 100%;
	}
	.search-opener.nav-link.mid {
		display: none;
	}
}
/* media query for phones just before the iPad break point at 768px - end */

/* media query for phones just before the iPad break point at 768px - start */
@media screen and (max-width:1024px) {

	.ecs-main-container-nbp {
		padding-top: 250px;
	}

	.ecs-main-container {
		padding-top: 200px;
	}

	.header + .section {
		padding-top: 240px !important;
	}

	#bag-opener {
		position: absolute;
		top: -23px;
		right: -8px;
	}
		/*	#bag-opener { position: absolute; top: 31px; right: -10px; }*/ /* This is for the updated header when approved */

		#bag-opener.nav-link.mid {
			padding: 0px 0px;
		}
	/*	.cart-total-qty { position: absolute; top: 3px; left: -41px; color: #fff; }*/ /* This is for the updated header when approved */
	.brand {
		position: relative;
		float: left;
		width: 100%;
	}

	.pf-product-images-wrapper-slider .w-icon-slider-left, .pf-product-images-wrapper-slider .w-icon-slider-right {
		top: 45%;
	}

	.pf-product-images-wrapper-slider .w-slider-nav {
		position: relative;
		bottom: 40px;
	}
	/*	.nav-link.mid .fa-shopping-cart { display: inline-block; position: absolute; right: 10px !important; width: auto; font-size: 2.3em; height: 30px; }*/ /* This is for the updatedheader when approved */

	/*	.nav-link.mid, .nav-link.top { display: inline-block; position: absolute; left: 0px; width: auto; }*/ /* This is for the updated header when approved */
	.nav-link.mid, .nav-link.top {
		display: inline-block;
		position: relative;
		float: left !important;
		width: auto;
	}

	.slider {
		height: 50vh;
		max-height: 500px;
		min-height: 358px;
	}
	/* Reduce the height of the main slider for mobile */
	.searchform-wrapper {
		width: 100%;
		max-width: 100%;
		float: left;
		margin: 0 auto;
		position: relative;
	}

	.mega-list.w--open { /*left: -209px;*/
		width: 768px;
		margin-top: 0px;
	}

	.mega-list.w-dropdown-list {
		position: fixed;
		max-width: 940px;
		left: 50%;
		transform: translate(-50%);
	}

	.mega-list.w-dropdown-link, .w-dropdown-toggle {
		padding: 0px 15px 2px 20px;
		white-space: inherit;
	}

	.nav-menu .w-dropdown .dropdown-list.w--open:last-of-type {
		top: 29px;
	}

	.mega-list .ecs-row {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		overflow: hidden;
	}
	/* Makes the dropdown menu columns equal height */

	.title-wrapper {
		margin-bottom: 20px;
	}

		.title-wrapper h2 {
			padding-top: 0px;
			font-size: 30px;
			line-height: 40px;
		}

	.flex-child, .flex-child.small, .flex-child.extra-small {
		width: 100%;
		margin-bottom: 10px;
		margin-right: 0%;
		margin-left: 0%;
	}

	.product-block h2 {
		margin-top: 30px;
		margin-bottom: 10px;
		font-size: 30px;
	}

	.img-overlay {
		height: 380px;
	}

	.pf-btn.pf-btn-sm {
		font-size: 15px;
	}

	.pf-btn.pf-btn-sm {
		font-size: 15px;
	}

	.productwrapper {
		min-height: 260px;
	}

	.pf-product-listing .ecs-column-3 {
		margin-top: 0%;
		margin-bottom: 0%;
	}

	.productwrapper {
		margin-bottom: 0px;
	}

	.top-right {
		display: none;
	}

	.ecs-row-full-width.blog-list .ecs-column-2 {
		width: 47.25%;
	}

	.ecs-row-full-width.blog-list .ecs-column-2 {
		margin-right: 5.5% !important;
	}

		.ecs-row-full-width.blog-list .ecs-column-2:nth-child(2) {
			margin-right: 0% !important;
		}

		.ecs-row-full-width.blog-list .ecs-column-2.blogPostStackIpad h1 {
			display: none;
		}
	.search-opener.nav-link.mid {
		position: absolute;
		top: -55px;
		right: 70px;
	}
	.search-opener span {
		display:inline-block;
	}
}

@media screen and (min-width:1024px) {
	.mega-list.w-dropdown-list {
		position: fixed;
		min-width: 940px;
		left: 50%;
		transform: translate(-50%); /*top: 150px*/
		;
	}
}

@media screen and (max-width:1062px) {
	.pf-quick-order-top .w-row .ecs-column-3 {
		width: 100% !important;
		margin-right: 0%;
	}
	/*	.quick-order-search { position: relative; float: left; width: 82%; margin-right: 1%; }
.pf-quick-order-quick-entry > input { width: 100%; } */
	.pf-quick-order-top .w-row .pf-qty-wrapper {
		position: absolute;
		top: 0;
		right: 0;
	}
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
	.ecs-main-container {
		padding-top: 210px;
	}
	/*.mega-list.w--open { left: -274px; }*/
	#bag-opener {
		position: absolute;
		top: -23px;
		right: -8px;
	}
	/*	#bag-opener { position: absolute; top: 31px; right: -8px; }*/ /* This is for the updated header when approved */
	.topnav .nav-link.mid {
		padding: 5px 10px 5px 12px;
	}

	.searchform-wrapper {
		width: 100%;
		max-width: 100%;
		float: left;
		margin: 0 auto;
		position: relative;
		padding: 0px 0px 0px 0px;
	}

	.w-col-4 {
		width: 100%;
	}

	.mid-footer .ecs-column-3 {
		width: 29.667%;
	}

	.ecs-column-3:not(.mega-col) {
		width: 100%;
	}

	.mid-footer .ecs-column-3:not(.mega-col) {
		width: 29.667%;
	}

	.ecs-column-3-2 {
		margin-right: 0%;
		width: 100%;
	}

		.ecs-column-3-2 .blogpost-text {
			padding: 20px 20px 40px 20px;
			text-align: center;
		}

	.ecs-column-4 {
		width: 100%;
		margin-right: 0;
		text-align: center;
	}

	.ecs-column-4-3 {
		width: 100%;
		margin-right: 0%;
		text-align: center;
	}

	.filter-dropdown {
		text-align: left;
	}
	/*	.results-filter-wrapper { display: none; }*/
	.w-col, .w-col-3, .w-col-4, .w-col-9 {
		width: 50% !important;
		padding-left: 1%;
		padding-right: 1%;
		text-align: center;
	}

		.w-col.w-col-9 {
			width: 75% !important;
			padding-left: 1%;
			padding-right: 1%;
			text-align: center;
		}

	.shrink .brand {
		padding-top: 15px;
		padding-bottom: 0px;
	}

	.shrink #bag-opener.nav-link.mid {
		padding: 3px 0px;
	}

	.shrink .brand {
		padding-top: 12px;
		padding-bottom: 2px;
	}

	.shrink .searchform-wrapper {
		margin: 10px auto 0;
	}

	.top-right {
		display: none;
	}

	.w-tab-link {
		padding: 9px 20px;
	}
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.ecs-main-container-nbp {
		padding-top: 247px;
	}

	.header + .section {
		padding-top: 239px !important;
	}

	.mega-list.w--open {
		width: 768px;
		margin-top: 5px;
		padding: 5px 5px 20px 5px;
	}

	.mid-footer, .bottomfooter {
		padding-left: 20px;
		padding-right: 20px;
	}

	.searchform-wrapper {
		padding-left: 10px;
	}

	.banner {
		top: 0px !important;
	}

	.shrink .brand {
		padding-top: 15px;
		padding-bottom: 0px;
	}

	.shrink #bag-opener.nav-link.mid {
		padding: 3px 0px;
	}

	.shrink .brand {
		padding-top: 12px;
		padding-bottom: 2px;
	}

	.shrink .searchform-wrapper {
		margin: 10px auto 0;
	}

	#pf-product-details-page .pf-product-image-thumb.w--current {
		height: 149px !important;
	}
}

/* iPad Pro (portrait) ----------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
	.mid-footer .ecs-column-3 {
		width: 29.667%;
	}

	.mega-list.w--open {
		margin-top: 9px;
	}

	#pf-product-details-page .pf-product-image-thumb.w--current {
		height: 149px !important;
	}
}

/* iPad Pro (landscape) ----------- */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
	.mega-list.w--open {
		margin-top: 3px;
		width: 1080px;
	}
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.the-website.pf-promotion-strip-enabled .ecs-main-container, .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp {
		padding-top: 210px;
	}
}

