/* CSS Document */
body {
    font-family: 'Montserrat', sans-serif !important;
}

@font-face {
    font-family: Montserrat;
    src: url(/Assets/release/site/css/fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: Montserrat-Light;
    src: url(/Assets/release/site/css/fonts/Montserrat-Light.ttf);
}

h1, h2, h3, h4 {
    font-family: 'Montserrat-Light', cursive;
}

.brand.w--current, .brand {
    padding-top: 0px;
}

.midnav {
    height: 80px;
}

.nav, .nav-menu, #prod-search-btn {
    background-color: #81bab5;
}

body {
    font-family: 'Montserrat', sans-serif !important;
    color: #444 !important;
    font-size: 16px;
}

.brand {
    padding-top: 0px;
    padding-bottom: 0px;
}


.remodal-wrapper .pf-add-cart-row .pf-qty-wrapper {
    margin-bottom: 5px;
}

.searchform-wrapper {
    margin: 0px auto 0;
}

    .searchform-wrapper .searchform i.fa-search {
        color: #fff;
        top: -28px;
        float: right;
        margin-right: 12px;
        font-size: 18px;
    }

.topnav {
    background-color: #eee !important;
}

.nav-link.mid{
    color: #81bab5;
}
.pf-my-statement-table .pf-btn.pf-btn-primary {
    color: #81bab5!important;
}

.topnav .nav-link.mid {
    padding: 5px 0px 2px 15px;
}

.nav-menu {
    background-color: #81bab5;
}

.nav {
    background-color: #81bab5;
}

#prod-search-btn, .button, .dropdown-toggle.pf-filter-item, .mid-footer {
    background-color: #81bab5;
}

.fa-search {
    font-size: 1.3em;
}

.pf-promotion-strip-enabled .ecs-main-container-nbp {
    padding-top: 164px !important;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    background-color: #81bab5;
}

    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        color: #81bab5;
    }

.megaheading, .nav-container .heading {
    color: #81bab5 !important;
    padding-bottom: 5px;
    padding-top: 15px;
}

.mega-list {
    overflow: auto;
}

    .mega-list.w--open {
        border: 1px solid #81bab5;
    }

    .mega-list.w-dropdown-list {
        width: 100%;
    }

.megalink:hover, a.dropdownlink:hover {
    color: #81bab5;
}

.pf-btn.pf-btn-primary, .pf-load-more-btn {
    border: 1px solid #81bab5 !important;
    background: #81bab5 !important;
}

    .pf-btn.pf-btn-primary:hover, .pf-load-more-btn:hover {
        color: #81bab5 !important;
        border-color: #81bab5 !important;
    }

.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    background-color: #81bab5;
}

    .ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border: 1px solid #81bab5 !important;
        background: #fff !important;
        color: #81bab5 !important;
    }

.w--current {
    color: #81bab5 !important;
}

#filter .cat-selected {
    color: #81bab5;
}

.brandsection .title-wrapper h2 {
    padding-top: 0px !important;
    margin-top: 0px !important;
}

#pf-product-details-page .pf-product-image-thumb.w--current {
    border: 1px solid #81bab5;
}

.mobile-search-icon .fa-search {
    color: #81bab5;
}

.social-link:hover {
    color: #81bab5;
}

.w--current, .topnav .nav-link.mid.w--current {
    color: #666 !important;
}

#sidenav .nav-link.w--current {
    color: #81bab5 !important;
}

.link-search-div .fa-search, .search-section .fa-search {
    color: #81bab5;
}

a.pf-normal-link:hover {
    color: #81bab5 !important;
}

.pf-btn.pf-btn-colour-one {
    background-color: #81bab5;
}

.pf-input.pf-hover-colour-one:focus, .pf-input.pf-hover-colour-one:hover, .pf-input.pf-hover-colour-two:focus, .pf-input.pf-hover-colour-two:hover {
    border-color: #81bab5;
}

.w-tab-menu .pf-tab-header.w--current {
    background-color: #81bab5;
    border-color: #81bab5;
}

.pf-my-orders-table .pf-btn.pf-btn-primary, .pf-my-returns-table .pf-btn.pf-btn-primary {
    color: #81bab5 !important;
}

.pf-filter-dropdown-icon .fa-caret-down, .pf-filter-item a:hover, a.pf-stock-notify-me-btn, a.pf-icon-link:hover, .contact-text a:hover, .tag, a.categorylink {
    color: #81bab5 !important;
}

.tags-container .tag {
    color: #81bab5 !important;
    border-color: #81bab5 !important;
}

.feefo-product-stars-widget {
    min-height: 20px;
}

.tag:hover {
    background: #81bab5;
    color: #fff !important;
}

#pfQoSearchBtn.pf-btn.pf-btn-colour-one:hover {
    background-color: #81bab5 !important;
    color: #81bab5 !important;
    border-color: #81bab5 !important;
}

.ui-state-focus, .ui-state-hover, .ui-widget-content .ui-menu-item.ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-focus, .ui-widget-header .ui-state-hover {
    color: #81bab5;
}

.wishlist-main-buttons .add-to-favorites-btn, .wishlist-main-buttons .pf-btn.pf-btn-secondary {
    background: #fff;
    border: 1px solid #81bab5;
    color: #81bab5;
}

    .wishlist-main-buttons .pf-btn.pf-btn-secondary:hover {
        background-color: #81bab5;
        border-color: #81bab5;
    }

.pf-add-all-to-wish-list-btn {
    background: #fff !important;
    border: 1px solid #81bab5 !important;
    color: #81bab5 !important;
}

    .pf-add-all-to-wish-list-btn:hover {
        background-color: #81bab5 !important;
        border-color: #81bab5 !important;
        color: #fff !important;
    }

.create-wish-List-btn {
    margin-bottom: 10px;
}

.wishlist-main-buttons .pull-left, .wishlist-main-buttons .pull-right {
    float: none;
}

.pf-btn.pf-btn-colour-one:hover {
    background-color: #fff;
    color: #81bab5;
    border: solid 0px;
}

.blog-details .post a, .pf-product-details-description a {
    color: #81bab5;
}

    .blog-details .post a:hover, .pf-product-details-description a:hover {
        color: #666;
    }

.w--current.pf-paging-btn.pf-paging-current {
    color: #fff !important;
}

.filter-subdropdown-link .pf-filter-dropdown-btn {
    margin: 2px 20px auto auto;
}

.brandsection.ecs-row-full-width .home-element-wrapper .icon-text {
    margin-top: 10px;
}

.bottomfooter a {
    color: rgba(255,255,255,.5)
}

    .bottomfooter a:hover {
        color: #fff;
    }

#pf-product-details-page .pf-product-image-thumb:nth-child(4n) {
    clear: inherit;
}

#pf-product-details-page .pf-product-image-thumb:nth-child(3n) {
    margin-left: 0;
}

.pf-terms-wrapper a {
    color: #81bab5;
}

.AboutList {
    float: left;
    width: 100%;
}

.paypal-footer {
    padding: 10px;
}

.payment-logos-footer h3 {
    margin-top: 0;
}

.pf-product-information-body a {
    color: #81bab5
}

.mid-footer.ecs-bottom-container {
    background-color: #81bab5 !important;
}

.nav-link.mid:hover, .fa-search:hover {
    color: #beb09c;
}
.pf-my-statement-table .pf-btn.pf-btn-primary:hover {
    color: #beb09c!important;
}

.fa-search {
    color: #81bab5;
    pointer-events: auto;
    padding: 0;
    top: 0;
    float: none;
    right: 0;
    color: #81bab5;
    font-size: 1.5em;
}

.center-flex {
    background-color: #e5f3f1;
    opacity: 0.8;
    text-shadow: none;
    color: #81bab5;
    background-image: none;
}

    .center-flex h3 {
        font-size: 24px;
        font-weight: 600;
    }

.filter-dropdown.filter-cats.filter-cats-main {
    background-color: #e5f3f1;
}

.pf-product-details-heading, .search-close {
    color: #81bab5;
}

.pf-product-details-info-wrapper tr {
    text-align: center;
}

.pf-product-details-info-wrapper td {
    padding: 5px;
    background-color: #81bab5;
    color: #fff;
}

#scrollUp {
    background-color: #81bab5;
    line-height: 40px;
}

.category-blog h2, .tag-container h2 {
    text-transform: uppercase;
    font-size: 30px;
}

.search-block {
    padding-top: 5px;
}

.search-section {
    margin-top: 43px;
}

.pf-promotion-strip-enabled .search-section {
    margin-top: 33px;
}

#search-results-wrapper #pf-product-listing {
    padding-top: 0;
    margin-top: 0;
}

#search-results-wrapper .pf-load-more-container {
    display: block;
    text-align: center;
}

#search-results-wrapper .pf-load-more-search-results-btn {
    min-width: 183px;
    height: 40px;
    background-color: #81bab5;
    color: #fff;
    padding: 15px;
}

#search-results-wrapper #pf-product-listing .w-col-12 .w-row {
    margin-bottom: 25px;
}

.pf-filter-item a {
    color: #000;
}

footer h3 {
    font-weight: 600 !important;
    font-size: 24px;
}

.nav-container.ecs-row .nav-link {
    font-size: 16px;
}

#sidenav .ecs-nav-group .heading {
    font-size: 16px !important;
}

#sidenav .ecs-nav-group .dropdownlink {
    font-size: 14px !important;
}

.delete-main-banner.banner-btn, .manage-main-banners.banner-btn {
    top: 80px;
}

.editMainbanner.banner-btn {
    top: 40px;
}

.flex-parent.pf-image-collection ul.mbss-textArea-btns {
    top: 100% !important;
}

.flex-child.extra-small {
    width: 24%;
}

.search-section .search-close {
    color: #444;
}

.the-website.pf-promotion-strip-enabled .ecs-main-container, .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp {
    padding-top: 178px !important;
}
/*.ecs-main-container {
    padding-top: 180px;
}*/
.mbss-textArea-btns .mbss-cancelTxtBtn,
.mbss-textArea-btns .mbss-saveTxtBtn {
    color: #fff;
}

.ecs-main-container-nbp {
    padding-top: 148px;
}

.ecs-main-container {
    padding-top: 135px;
}

.create-the-style-slider {
    text-align: center;
}

#pf-gallery-image-products {
    flex-wrap: wrap;
}

    #pf-gallery-image-products .flex-col {
        flex: 1 0 21%;
        margin: 10px;
        padding: 10px;
    }

.style-container {
    flex-wrap: wrap;
    display: flex;
}

    .style-container .style-item {
        margin: 10px 10px 20px 10px;
        flex: 1 0 21%;
    }

.create-the-style-arrows {
    color: #81bab5;
}

.ecs-nav-group .dropdownlink.w-dropdown-link {
    font-size: 12px;
    padding: 0;
}

.searchform-wrapper .searchform {
    margin-top: 20px;
}

.pf-image-collection a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pf-sale-cart-tag p {
    width: 100%;
}
#pf-product-details-page .pf-sale-cart-tag p {
    width: 50%;
}

.pf-sale-cart-tag {
    display: none;
    color: black !important;
    border-top: #81bab5 solid 6px !important;
    margin-top: 15px;
}

    .pf-sale-cart-tag a {
        color: black !important;
    }

.mega-list .ecs-column-4-3 {
    width:73%;
}
.productwrapper {
    border: none;
}
.pf-nav-image {
    margin-bottom: 10px;
}
.subscribe-wrapper .pf-btn-primary {
    line-height:12px;
}
#signup-tbx {
    position:relative;
    top:3px;
}
.banner-link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
}
.pf-tablet-banner, .pf-mobile-banner {
    display:none;
}
.pf-desktop-banner .slider {
    min-height: 100%;
    height: auto;
}

.pf-desktop-banner .w-slide {
    padding-top: 30.75%;
}
.slider {
    max-height:800px;
}
.slider-container.mbss-editmode {
    position:absolute;
}
.slider-container.mbss-editmode .mbss-textArea-btns {
    top:0!important;
}

.banner-text-medium {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 6px;
}
.banner-text-small {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 3px;
}
a.move-btn-list {
    color: #666;
}
@media screen and (max-width:1024px) {
    .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp.pf-tablet-banner {
        padding-top:232px!important;
    }
    .pf-desktop-banner {
        display: none;
    }
    .pf-tablet-banner .slider {
        min-height: 100%;
        height: auto;
    }
    .pf-tablet-banner .w-slide {
        padding-top: 39.06%;
    }
    .pf-tablet-banner {
        display: block;
    }

    .banner-text-large {
        font-size: 32px;
        line-height: 40px;
    }
    .banner-text-medium {
        font-size: 24px;
        line-height: 30px;
    }
    .banner-text-small {
        font-size: 20px;
        line-height: 24px;
    }

    .slider-container {
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
        padding: 10px 18px 12px 18px;
        margin-top: 12px;
    }

    .midnav {
        height: unset;
    }

    .search-opener.nav-link.mid {
        position: absolute;
        top: -55px;
        right: 70px;
    }


    .search-opener span {
        display: inline-block;
    }

    .search-section {
        margin-top: 61px !important;
    }

    .pf-promotion-strip-enabled .search-section {
        margin-top: 91px !important;
    }

    .ecs-main-container {
        padding-top: 200px;
    }

    .center-flex h3 {
        font-size: 18px;
        margin: 0;
        padding: 10px 0;
    }
    .pf-image-collection .center-flex h3 {
        font-size: 24px;
    }

    .searchform-wrapper .searchform {
        margin-top: 0;
    }

    .shrink .nav-link.nav-dropdown {
        padding-top: 1px;
        padding-bottom: 2px;
    }
}

@media screen and (min-width:768px) {
    .shrink .brand {
        padding-top: 0;
        padding-bottom: 0;
    }

    .shrink .topnav {
        height: 28px;
    }

    .shrink .topnav .nav-link.mid {
        padding: 5px 0px 2px 15px;
    }

    .shrink .searchform-wrapper {
        margin: 0px auto 0;
        max-height: none;
    }

    .shrink .nav-link.mid,
    .shrink .bag-opener.nav-link.mid {
        padding: 32px 0 25px 10px;
    }

    .shrink .nav-link {
        padding-top: 11px;
        padding-bottom: 10px;
    }
}

/* media query for phones just before the iPad break point at 768px - start */
@media screen and (max-width:767px) {
    .pf-desktop-banner, .pf-tablet-banner {
        display: none;
    }
    .editMainbanner.banner-btn, .delete-main-banner.banner-btn {
        left: 80px;
    }
    .add-main-banner.editMainbanner.banner-btn {
        left:auto;
    }
    .pf-mobile-banner .slider {
        min-height: 100%;
        height: auto;
    }
    .pf-mobile-banner .w-slide {
        padding-top:78.23%;
    }
    .pf-mobile-banner {
        display: block;
    }

    .banner-text-large {
        font-size: 24px;
        line-height: 32px;
    }
    .banner-text-medium {
        font-size: 20px;
        line-height: 26px;
    }
    .banner-text-small {
        font-size: 18px;
        line-height: 22px;
    }

    .slider-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
        padding: 6px 14px 8px 14px;
        margin-top: 10px;
    }

    .menu-button {
        color: #81bab5 !important;
    }

    .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp {
        padding-top: 142px !important;
    }

    section .ecs-row {
        padding-left: 20px;
        padding-right: 20px;
    }

    .search-opener.nav-link.mid {
        display: none;
    }

    .pf-image-collection .center-flex h3 {
        font-size: 18px;
    }

    .flex-child.extra-small {
        width: 49%;
    }

    .ecs-main-container {
        padding-top: 200px !important;
    }

    .search-section {
        margin-top: 30px !important;
    }

    .pf-promotion-strip-enabled .search-section {
        margin-top: 60px !important;
    }

    .heading.w-dropdown-link {
        padding-left: 0;
    }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .shrink #bag-opener.nav-link.mid {
        padding: 0;
    }

    .search-section {
        margin-top: 61px;
    }

    .pf-promotion-strip-enabled .search-section {
        margin-top: 91px;
    }

    .center-flex h3 {
        font-size: 16px;
    }

    #pf-product-listing .w-col-3 {
        width: 50% !important;
    }

    #pf-product-listing .w-col-12 {
        width: 100% !important;
    }

    #pf-product-list-page.pf-page-wrapper {
        padding-top: 195px;
    }
}

@media screen and (max-width: 570px) {
    #pf-gallery-image-products .flex-col {
        flex: 0 44%;
    }

    .style-container .style-item {
        flex: 0 44%;
    }

    .flex-child.extra-small {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    .pf-popup-cart-loader {
        padding-left: 10px;
        padding-right: 10px;
        overflow-x: scroll;
    }

    .notification-image, .lobibox-notify-icon-wrapper {
        display: none;
    }

    .lobibox-notify .lobibox-notify-body {
        margin: 10px !important;
    }

    .ecs-main-container-nbp {
        padding-top: 143px !important;
    }
    .mbss-text ul.redactor-toolbar li a.re-button-icon {
        padding: 6px !important;
        height: 29px !important;
    }
}

@media screen and (max-width: 414px) {
    .search-section {
        margin-top: 30px !important;
    }

    .pf-promotion-strip-enabled .search-section {
        margin-top: 61px !important;
    }

    .ecs-main-container-nbp {
        padding-top: 176px !important;
    }

    .ecs-main-container {
        padding-top: 180px !important;
    }

    #pf-gallery-image-products .flex-col {
        flex: 0 100%;
    }

    .style-container .style-item {
        flex: 0 100%;
    }
}

@media screen and (max-width: 375px) {
    .search-section {
        margin-top: 10px !important;
    }

    .pf-promotion-strip-enabled .search-section {
        margin-top: 41px !important;
    }

    .ecs-main-container-nbp {
        padding-top: 156px !important;
    }
}

@media screen and (max-width: 360px) {
    .search-section {
        margin-top: 10px !important;
    }

    .pf-promotion-strip-enabled .search-section {
        margin-top: 41px !important;
    }
}

@media screen and (max-width: 320px) {
    .search-section {
        margin-top: -2px !important;
    }

    .pf-promotion-strip-enabled .search-section {
        margin-top: 28px !important;
    }

    .the-website.pf-promotion-strip-enabled .ecs-main-container, .the-website.pf-promotion-strip-enabled .ecs-main-container-nbp {
        padding-top: 49% !important;
    }

    .ecs-main-container-nbp {
        padding-top: 143px !important;
    }
}

#list-products-wrapper .productwrapper .pf-new-product-tag {
    font-size: 12px;
}
