/* Global Settings for Core */
/* Import navigation core */

/* General */
.pf-button p {
    padding: 0;
    margin: 0;
}

a[disabled] {
    pointer-events: none;
}

/* MAIN NAVIGATION */
ul#main-nav.navigation.justify {
    text-align: justify;
    min-width: 500px;
}

    ul#main-nav.navigation.justify:after {
        content: '';
        display: inline-block;
        width: 100%;
    }

    ul#main-nav.navigation.justify > li {
        display: table-cell;
        width: 1%;
        float: none;
    }

ul#main-nav.navigation > li {
    text-align: center;
    float: left;
}

.search-section {
    left: 0%;
    top: 162px;
    right: 0%;
    bottom: auto;
    z-index: 999;
    display: none;
    margin-top: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 4px 4px 11px -1px rgba(0, 0, 0, 0.34);
}

#sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 5px;
    transition: 0.5s;
}

.sidenav-btn-wrapper {
    float: right;
    padding: 5px;
    color: #000;
}

.sidenav #sidenav-close-btn {
    position: relative;
    top: 4px; /*left: 227px;*/
    float: right;
    right: 15px;
    font-size: 30px;
    padding-bottom: 20px;
    cursor: pointer;
}

.sidenav a {
    width: 100%;
}

#navbackground {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: opacity 0.5s;
    opacity: 0;
    background-color: black;
}

#front-end {
    transition: margin-left .5s;
}

#iframe-modal.remodal {
    max-width: 100%;
    width: auto;
}

#map {
    height: 400px;
}

.placesWrapper {
    padding-top: 10px;
}

.placeItem {
    background-color: lightblue;
    padding: 10px;
    width: 24%;
    margin: 5px;
}

    .placeItem .PlaceName h3 {
        margin-top: 0;
    }

.placesMapSpacer {
    padding-top: 10px;
}

#infoWindow h3 {
    margin-top: 0;
}

.search-block {
    display: flex;
}

#expanded-search-tbx {
    margin: 0 10px;
}

ul#main-nav.navigation > li > ul li {
    text-align: left;
}

ul#main-nav.navigation li {
    list-style: none;
}

ul#main-nav.navigation > li > ul {
    position: absolute;
    z-index: 9999;
}

ul#main-nav.navigation .nav-col {
    float: left;
}
/* COMMON */
ul.navigation .arrow.arrow-up {
    display: none;
}

ul.navigation .dropdown-nav {
    display: none;
}
/* MOBILE LEFT NAVIGATION */
ul#mobile-left {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
}
/* MOBILE TOP NAVIGATION */
ul#mobile-top {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

a:focus {
    outline: none;
}
/* bootsrap overrides and generic classes*/
.breadcrumb {
    margin-bottom: 0px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-justify {
    text-align: justify;
}

.hidden-xs {
    display: none;
}

.visible-xs {
    display: table-cell;
}

    .visible-xs.block {
        width: 100%;
        display: inline-block;
    }

.core-hidden {
    display: none;
}

a.hidden {
    display: none;
}

ul {
    padding: 0px;
    margin: 0px;
}

    ul li {
        margin-left: 0;
        list-style: disc inside;
    }

    ul.ui-autocomplete li {
        list-style: none;
    }

.w-slide {
    background-size: cover !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
}

.embed-container {
    padding-bottom: 56.25%;
    height: 100%;
    max-width: 100%;
    display: block;
    /*height: 800px;*/
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
}

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: #000;
    }

.colour-section {
    width: 100%;
    padding-top: 30px;
}

.begin-wrapper {
    display: none;
}
/* BANNER LAYERS RESPONSIVE */
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
    font-size: 15px;
}

.banner-drop-options {
    display: none;
}
/* add fade class to carousel to make transitions fade through rather than scroll */
.carousel.banner-fade .carousel-inner .item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}

.carousel.banner-fade .carousel-inner .active {
    opacity: 1;
}

    .carousel.banner-fade .carousel-inner .active.left,
    .carousel.banner-fade .carousel-inner .active.right {
        left: 0;
        opacity: 0;
        z-index: 1;
    }

.carousel.banner-fade .carousel-inner .next.left,
.carousel.banner-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel.banner-fade .carousel-control {
    z-index: 2;
}

.carousel-fade .carousel-inner .item {
    opacity: 0;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    -webkit-transition-property: opacity;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
    opacity: 1;
}

    .carousel-fade .carousel-inner .active.left,
    .carousel-fade .carousel-inner .active.right {
        left: 0;
        opacity: 0;
        z-index: 1;
    }

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-control {
    z-index: 2;
}

#agentinfo-tab-content a {
    color: #333 !important;
}

/* Mobile First */
.the-website {
    position: relative;
}

#scrollUp {
    /*background-image: url(../../../../../Content/site-images/master/scrollTop.jpg);*/
    bottom: 10px;
    color: #FFFFFF;
    height: 38px;
    width: 38px;
    right: 10px;
    opacity: 0.8;
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    background-color: #666;
}

    #scrollUp.move-up-cookie {
        bottom: 30px;
    }

.mobile-menu-trigger span {
    font-size: 20px;
    cursor: pointer;
}

#bag {
    position: relative;
}

    #bag li {
        list-style: none;
    }

#order-drop-wrapper {
    position: absolute;
}

img.not-selected {
    display: none;
}

img.thumb-img {
    cursor: pointer;
}

.product-main-img-holder {
    height: 600px;
}

#ytPop {
    background-color: #000;
    width: 600px;
    height: 450px;
    position: relative;
}

#videoList li {
    text-align: left;
    width: 120px;
    height: 90px;
}

    #videoList li:hover {
        opacity: 0.9;
    }

    #videoList li .video-thumb {
        position: absolute;
    }

    #videoList li .ytPopBtn {
        position: relative;
    }

    #videoList li .vidPlayBtn {
        position: absolute;
        left: 20px;
        top: 20px;
    }

.country-dropdown {
    width: 150px;
}

#info ul,
.postContent ul {
    margin-left: 14px;
}

    #info li,
    .postContent ul li {
        list-style: disc;
    }

.new-icon {
    position: absolute;
}

.upper-case,
.text-uppercase {
    text-transform: uppercase;
}

.padded-top {
    padding-top: 30px;
}

.mbss-banner-layer {
    position: absolute;
    top: 0px;
    left: 0px;
}
/* Product View */
.nav-tabs {
    font-size: 12px;
}

#main-image-wrapper a {
    cursor: zoom-in;
}

.variantRow {
    display: none;
}
/* Cart Global */
.cart-table th {
    text-transform: uppercase;
    white-space: nowrap;
}

.topheader {
    background-color: white;
}

th.cart-code,
th.cart-price,
th.cart-retail-price,
th.cart-qty,
th.cart-remove,
th.cart-total-ex-tax,
th.cart-total-tax,
th.cart-total-inc-tax {
    display: none;
}

td.cart-code,
td.cart-price,
td.cart-retail-price,
td.cart-qty,
td.cart-remove,
td.cart-total-ex-tax,
td.cart-total-tax,
td.cart-total-inc-tax {
    display: none;
}

th.top-cart-footer-code,
th.top-cart-footer-description,
th.top-cart-footer-retail-price,
th.top-cart-footer-price,
th.top-cart-footer-qty,
th.top-cart-footer-total-ex-tax,
th.top-cart-footer-total-tax,
th.top-cart-footer-total-inc-tax {
    display: none;
}

th.checkout-cart-footer-padding-code,
th.checkout-cart-footer-padding-retail-price,
th.checkout-cart-footer-padding-price,
th.checkout-cart-footer-padding-qty,
th.checkout-cart-footer-padding-remove,
th.checkout-cart-footer-padding-total-ex-tax,
th.checkout-cart-footer-padding-total-tax {
    display: none;
}

p.cart-code,
p.cart-price,
p.cart-retail-price,
p.cart-stock,
p.cart-total-combined {
    font-style: italic;
    margin-top: 0.1em;
    margin-bottom: 0.2em;
}

p.cart-qty {
    margin-top: 15px;
    margin-bottom: 0;
}

select.cart-qty.qty-tbx {
    margin: 0 auto;
}

td.cart-description {
    text-align: left;
}

th.checkout-cart-footer-row-label {
    text-align: right;
}

/* Top Cart */
#top-cart {
    margin-top: 20px;
    margin-bottom: 0;
}

#bag-closer {
    float: right;
}

#top-cart-below .proceed {
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 20px;
}

/* Checkout */
.checkout-discount-area {
    margin-top: 20px;
}

.order-total-label {
    font-weight: bold;
}

.order-total-label,
.order-total-value {
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
}

textarea.input {
    padding-top: 12px;
    padding-bottom: 12px;
}

.qtywrapper.qtyWrapperDesktop {
    display: none;
}

.qtywrapper.qtyWrapperMobile {
    display: inline-block;
}

.cart-table img {
    margin-left: auto;
    margin-right: auto;
}

.table.cart-table {
    width: 100%;
    text-align: center;
}

#top-cart-holder {
    margin: 15px;
    display: none;
}

table.cart-table th {
    font-size: 13px;
}

table.cart-table tbody > tr > td,
table.cart-table tbody > tr > th {
    padding: 10px;
}

#billing,
#delivery {
    font-size: 12px;
}

#billing-address-auto-btn,
#billing-address-manual-btn,
#delivery-address-auto-btn,
#delivery-address-manual-btn {
    display: inline-block;
    margin-bottom: 10px;
}

#billPickAddressWrapper .checkbox,
#delPickAddressWrapper .checkbox {
    margin-bottom: 10px;
}

.checout-btn {
    display: inline-block;
}

#delivery-options-frame h4 {
    margin-top: 0;
}

.tcLabel,
.paymentLabel {
    display: inline-block;
    margin-bottom: 20px;
}

.deliveryCost {
    padding-left: 10px;
    padding-right: 10px;
}

#validNotes {
    float: right;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #a82031;
}

.payment-confirmation-page {
    margin-top: 30px;
    margin-bottom: 30px;
}

#billing,
#delivery {
    padding-left: 10px;
    padding-right: 10px;
}

.delivery h4 {
    text-align: left;
}

#tc {
    margin-right: 5px;
    margin-top: 5px;
}

.paypalAnnotation {
    margin-top: 10px;
    display: block;
}

.delivery-options {
    margin-bottom: 30px;
}

    .delivery-options ul li {
        list-style: none;
    }
/* blog */
.post-share-buttons {
    padding: 20px 0;
}

.fb-share-wrapper {
    float: left;
    margin-right: 15px;
}

.twitter-share-wrapper {
    float: left;
    margin-right: 15px;
}

.google-share-wrapper {
    overflow: auto;
    float: left;
}

.mbss-image-scale {
    max-width: 100%;
    height: auto;
}
/*cookie bar*/
#cookie-bar {
    background: #3D3D3D !important;
    padding: 5px 40px !important;
    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 22px !important;
}

    #cookie-bar a.cookie-link {
        color: #FFF;
        text-decoration: underline;
        padding: 0;
        margin-left: 0;
    }

    #cookie-bar a.cb-enable {
        padding: 3px 8px;
    }

    #cookie-bar .cb-enable:hover {
        background: #119F9B none repeat scroll 0% 0% !important;
    }

    #cookie-bar .cb-enable {
        background: #008F8A none repeat scroll 0% 0% !important;
    }
/* product filter */
.cat-selected {
    font-weight: bold;
    text-decoration: underline;
}

/*#filter .subdropdown-toggle .filter-dropdown-icon{
    display:none;
}*/
#filter .filter-subdropdown-list-2 {
    padding-left: 15px;
}

#filter .cat-selected {
    font-weight: bold;
}

#filter .filter-brands, #filtersOnly .filter-specs {
    display: none;
}

/* product list and view */
.productOptions.highlight {
    border: 2px solid #d00000;
}

.productOptionWarn {
    color: #d00000;
    display: none;
}

.socialwrapper > div {
    float: left;
    margin-right: 10px;
}

.socialwrapper .social.fb {
    top: 0;
}

.socialwrapper .social.googleplus {
    top: 0;
}

.socialwrapper .social.pinterest {
    top: -3px;
}

.post-share-buttons > div {
    float: left;
    margin-right: 10px;
    position: relative;
}

.post-share-buttons .pinterest-share-wrapper {
    top: -3px;
}

a.platform-container-link {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
}

/* Parts viewer button */
input.button-pv-cart {
    font-size: 12px;
    padding: 5px 2px;
    min-width: 110px;
    margin: 0;
}

html input.w-button.button-pv-cart {
    border-style: solid;
    border-width: 2px;
}

/* Modal login styles */
#modal-login-warning {
    display: none;
}

#login-modal .w-button {
    float: left;
}

.forgotten-password {
    margin-bottom: 10px;
    margin-left: 20px;
    float: right;
}

/* raise toastr above the modal bg */
.lobibox-notify-wrapper {
    z-index: 15000 !important;
}

/* Errors */
.errormessage-block {
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #fff;
    color: rgba(160, 0, 0, 0.8);
    text-align: center;
    border: 1px solid rgba(160, 0, 0, 0.8);
}

    .errormessage-block p {
        padding: 0;
        margin: 0;
    }

    .errormessage-block h1,
    .errormessage-block h2,
    .errormessage-block h3,
    .errormessage-block h4 {
        margin: 0.3em 0;
    }

.warningmessage {
    color: red;
}

.errormessage-highlight {
    color: red;
}
/* checkout address lookup */
.postcode-lookup-input {
    clear: both;
    max-width: 300px;
    text-transform: uppercase;
}

.postcode-lookup-btn {
    clear: both;
    max-width: 300px;
    width: 100%;
}

.postcode-lookup-dropdown {
    margin-top: 10px;
}

input.validity-erroneous {
    border: 2px solid #a82031 !important;
}

#makePaymentBtn {
    width: 100%;
}

#checkout-login .reset-pass-btn {
    float: right;
}

.guest-checkout-panel {
    margin-top: 40px;
    margin-bottom: 40px;
}

.mobile-filter-visible {
    display: block !important;
    opacity: 1 !important;
}

.filter-heading.mobile {
    cursor: pointer !important;
}

.filter-heading-icon {
    font-size: 32px;
}

.pf-sales-account-page #UsersTbl_filter {
    display: none;
}

.salesAgentInfoBanner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    background-color: #449d44;
    padding: 10px;
    color: white;
}

#useBillingBtn {
    margin: 10px 0;
}

.worldpay-payment-option-description {
    display: inline-block;
}

    .worldpay-payment-option-description > table {
        margin-top: 10px;
    }

/* Modals */
div.pf-platform-modal {
    padding: 10px;
}

.pf-platform-modal-title {
    font-size: 18px;
    text-align: center;
}

.pf-platform-modal-text {
    padding: 10px;
}

.pf-platform-modal hr {
    color: #ccc;
}

/* Download Page */
.downloads-dropdown {
    height: auto;
}

.downloads-widget .w-icon-dropdown-toggle {
    bottom: 10px;
}

.downloads-dropdown .w-dropdown-list.w--open {
    border-bottom: none;
    border-top: 1px solid #989c9f !important;
    margin-bottom: 0px;
    display: table;
}

.downloads-dropdown-list {
    border-color: #eee;
}

.downloads-txt {
    margin-left: 20px;
}

#search-div-block-compact > .downloads-link {
    vertical-align: 4px;
    color: #000;
    text-align: center;
}

.downloads-wrapper .addListItem2Btn {
    bottom: -50px;
}

.downloads-wrapper .downloads-dropdown-list .addListItem2Btn {
    bottom: -31px;
    right: 80px;
}

.ecs-main-container-nbp .mbss-listItem2-image, .ecs-main-container-nbp .image-placeholder {
    min-width: 130px;
}

mbss-listItem2-wrapper .w-container w-row .mbss-hover-highlight {
    width: 100%;
}

.downloads-dropdown {
    margin: 0;
    display: block;
}

    .downloads-dropdown p {
        margin-bottom: 5px;
    }

.downloads-dropdown {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 10px;
    border-style: none none solid;
    border-width: 1px;
    border-color: #989c9f;
    color: #3c3c3a;
    text-align: left;
    text-decoration: none;
    padding-top: 5px !important;
}

.downloads-dropdown-list {
    border-bottom: 0;
}

    .downloads-dropdown-list.w--open {
        box-shadow: none;
        position: relative;
        height: auto;
        padding: 20px 10px 10px;
        background-color: transparent;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        color: #222;
        font-weight: 300;
        margin-top: 0px;
    }

.ecs-main-container-nbp .mbss-text,
.dropdown-faq.w-icon-dropdown-toggle {
    color: #000 !important;
}

.downloads-dropdown {
    width: 100%;
    padding: 0;
    position: relative;
    vertical-align: top;
    color: #222;
    padding: 0;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
    display: inline-block;
}

.downloads-div {
    display: flex;
    margin-bottom: 20px;
}

.dropdown-faq:before {
    bottom: 5px;
    content: '\002B';
    font-size: 24px;
    font-family: Georgia, Times, 'Times New Roman', serif;
    float: right;
}

.downloads-dropdown.w--open .dropdown-faq:before {
    content: '\2212';
}

.downloads-wrapper .addListItem2Btn {
    bottom: -50px;
}

.downloads-wrapper .downloads-dropdown-list .addListItem2Btn {
    bottom: -31px;
    right: 80px;
}

.downloads-page-wrapper .mbss-listItem2-image, .downloads-page-wrapper .image-placeholder {
    min-width: 130px;
}

.question-link {
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
}

    .question-link .mbss-listItem-text p {
        padding-top: 0;
    }

.downloads-dropdown-list .mbss-listItem2-wrapper.w-container {
    max-width: unset;
}

.mbss-text.question-link ul.mbss-textArea-btns {
    left: 469px;
    top: -67px !important;
}

.mbss-text.downloads-dropdown-list span.re-button-tooltip {
    top: 0 !important;
}

.mbss-text.downloads-dropdown-list ul.redactor-toolbar {
    top: -38px !important;
    left: -38px !important;
}

.mbss-text.downloads-dropdown-list ul.mbss-textArea-btns {
    width: 78px;
}

.downloads-dropdown-list a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    color: #e5005b;
}

.downloads-dropdown-list a:hover {
    color: #e5005b;
}

/* Size dependant styles */
/* Bootstrap 'sm' size, tablets portrait */
@media (min-width: 768px) {
    .qtywrapper.qtyWrapperDesktop {
        display: inline-block;
    }

    .qtywrapper.qtyWrapperMobile {
        display: none;
    }

    .hidden-xs {
        display: table-cell;
    }

    .visible-xs {
        display: none;
    }

    .product-nav {
        width: 100%;
        position: absolute;
        left: 0px;
        margin: 0px;
        padding: 0px;
    }

    /* Product View */
    .nav-tabs {
        font-size: inherit;
    }

    /* Cart Global */
    th.cart-total-combined {
        display: none;
    }

    td.cart-total-combined {
        display: none;
    }

    th.top-cart-footer-total-combined {
        display: none;
    }

    th.cart-total-ex-tax,
    th.cart-total-tax,
    th.cart-total-inc-tax {
        display: table-cell;
    }

    td.cart-total-ex-tax,
    td.cart-total-tax,
    td.cart-total-inc-tax {
        display: table-cell;
    }

    th.top-cart-footer-total-ex-tax,
    th.top-cart-footer-total-tax,
    th.top-cart-footer-total-inc-tax {
        display: table-cell;
    }

    th.checkout-cart-footer-padding-total-ex-tax,
    th.checkout-cart-footer-padding-total-tax {
        display: table-cell;
    }


    /* Checkout */
    table.cart-table tbody > tr > td,
    table.cart-table tbody > tr > th {
        padding: 5px;
    }

    #billing,
    #delivery {
        font-size: inherit;
    }

    #checkout-login .sign-in-panel {
        padding-right: 60px;
    }

    #checkout-login .guest-checkout-panel {
        padding-left: 60px;
    }

    .guest-checkout-panel {
        margin-top: 0;
        margin-bottom: 0;
    }

        .guest-checkout-panel a.checkout-btn {
            margin-top: 46px;
        }

    .sidenav-btn-wrapper {
        display: none;
    }
}

/* Bootstrap 'md' size, tablets landscape, small screen PCs */
@media (min-width: 992px) {
    /* Cart Global */
    th.cart-price,
    th.cart-retail-price,
    th.cart-qty {
        display: table-cell;
    }

    td.cart-price,
    td.cart-retail-price,
    td.cart-qty {
        display: table-cell;
    }

    th.top-cart-footer-description,
    th.top-cart-footer-price,
    th.top-cart-footer-retail-price {
        display: table-cell;
    }

    th.checkout-cart-footer-padding-price,
    th.checkout-cart-footer-padding-retail-price,
    th.checkout-cart-footer-padding-qty {
        display: table-cell;
    }

    p.cart-price,
    p.cart-retail-price,
    p.cart-qty {
        display: none;
    }
}

/* Bootstrap 'lg' size, PCs */
@media (min-width: 1200px) {
    /* Cart Global */
    th.cart-code,
    th.cart-remove {
        display: table-cell;
    }

    td.cart-code,
    td.cart-remove {
        display: table-cell;
    }

    th.top-cart-footer-code,
    th.top-cart-footer-qty {
        display: table-cell;
    }

    th.checkout-cart-footer-padding-code,
    th.checkout-cart-footer-padding-remove {
        display: table-cell;
    }

    p.cart-code,
    p.cart-remove {
        display: none;
    }

    /* Webflow overrides */
    div.w-container {
        max-width: 1200px;
    }
}

.pf-wishlist-quick-entry {
    display: inline-block;
}

.pf-flip-second-image {
    display: none;
}

.pf-flip-first-image:hover {
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
}

.flip-image-big, .flip-image-big .mbss-flip-item img {
    height: 400px;
}

.flip-image-small, .flip-image-small .mbss-flip-item img {
    height: 300px;
}

.mbss-flip-wrapper .mbss-flip-item img {
    max-height: unset;
}

#pf-product-details-page .pf-view-cart {
    display: inline-block;
    float: right;
    margin-top: 5px;
}

#pf-product-details-page .pf-sale-cart-tag p {
    display: inline-block;
    margin-top: 5px;
}

#pf-product-details-page .pf-sale-cart-tag  {
    display: none;
    margin-bottom: 40px;
    border: 1px solid #ccc;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}

@media (max-width: 991px) {
    .search-opener span {
        display: none;
    }
}

.pf-postcode-lookup-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

    .pf-postcode-lookup-inline .postcode-lookup-input {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: unset;
        width: auto;
    }

    .pf-postcode-lookup-inline .postcode-lookup-btn {
        flex-grow: 0;
        flex-shrink: 0;
        max-width: unset;
        width: auto;
        height: 38px;
    }

    .pf-postcode-lookup-inline .postcode-lookup-dropdown {
        width: 100%;
        margin-top: 0;
    }

#pf-currency-selector {
    border: none;
    background: bottom;
}

.search-block {
    padding-top: 5px;
}

.search-section {
    margin-top: 188px;
}

#search-results-wrapper #pf-product-listing {
    padding-top: 0;
    margin-top: 0;
}

#search-results-wrapper .pf-load-more-container {
    display: block;
    text-align: center;
}

#search-results-wrapper .pf-load-more-search-results-btn {
    min-width: 183px;
    height: 40px;
    background-color: #81bab5;
    color: #fff;
    padding: 15px;
}

#search-results-wrapper #pf-product-listing .w-col-12 .w-row {
    margin-bottom: 25px;
}

.request-check-stock-wrapper {
    padding: 10px;
    background-color: #eee;
    margin-top: 15px;
}

.request-check-stock-wrapper form .ecs-column-2:nth-child(2) {
    margin-right: 0 !important;
}

.request-check-stock-wrapper .pf-btn.pf-btn-primary {
    width: 150px;
}
@media (max-width: 500px) {
    .pf-sale-cart-tag p {
        width:50%;
    }
}